let dateTime = {},
	unkn;

// 获取今日短日期
dateTime.getToDay = function() {
	var date = new Date();
	return dateTime.getDate(date)
};

// 获取当前月份  前或后  月份日期(不以天数计算，如2月28,后查，3月28, 前查 1月28)
dateTime.getDateBegin = function(num) {
	var date = new Date();
	return getMonthDate(date, num)
};

// 获取指定日期， 传入年月日，与 想要几天前后日期  正数向后 负数向前
dateTime.getAppointDay = function(date, dayNum) {
	let dayTime = '';
	// 转换日期的时间戳
	if (!dayNum) {
		dayTime = 0;
	} else {
		dayTime = dayNum && dayNum >= 0 ? parseInt(dayNum) * 24 * 60 * 60 * 1000 : parseInt(dayNum) * 24 * 60 * 60 *
			1000;
	}
	if (!date) {
		return
	}
	// 选择年月日
	let currentDate = new Date(date);
	// 转换成时间戳
	let time = currentDate.getTime(currentDate);
	// 指定日期的时间戳
	let appointTime = parseInt(time + dayTime);
	// 新的时间戳
	let newTime = new Date(appointTime);

	// 年 
	let year = newTime.getFullYear();
	// 月
	let month = newTime.getMonth() + 1;
	if (month > 0 && month < 10) {
		month = "0" + month;
	}
	// 日  
	let day = newTime.getDate();
	if (day > 0 && day < 10) {
		day = "0" + day;
	}
	// 指定的日期	
	let appointDate = year + '-' + month + '-' + day

	return appointDate
};

// 把世界时间格式转换成年月日
dateTime.YMDFormat = function(string) {
	let date = new Date(string);
	let y = date.getFullYear();
	let m = date.getMonth() + 1;
	m = m < 10 ? ('0' + m) : m;
	let d = date.getDate();
	d = d < 10 ? ('0' + d) : d;
	const dateFormat = y + '-' + m + '-' + d;;
	return dateFormat
};
// 通过字符串日期获取 01月15日
dateTime.MDFormat = function(string) {
	let date = new Date(string);
	let y = date.getFullYear();
	let m = date.getMonth() + 1;
	m = m < 10 ? ('0' + m) : m;
	let d = date.getDate();
	d = d < 10 ? ('0' + d) : d;
	const dateFormat =  m + '月' + d +'日';
	return dateFormat
};



//通过字符日期获取星期几(int类型)
dateTime.getWeekInt = function(date) {
	var arr = date.split("-"); //日期为输入日期，格式为 2013-3-10
	var wkdate = new Date(arr[0], parseInt(arr[1] - 1), arr[2]);
	return wkdate.getDay(); //就是你要的星期几
}


//通过字符日期获取星期几(周*)
dateTime.getWeekZhou = function(date) {
	var weekInt = dateTime.getWeekInt(date);
	if (weekInt == 1) return "周一";
	if (weekInt == 2) return "周二";
	if (weekInt == 3) return "周三";
	if (weekInt == 4) return "周四";
	if (weekInt == 5) return "周五";
	if (weekInt == 6) return "周六";
	if (weekInt == 0) return "周日";
}



//获取指定字符日期 n天之后的日期字符串     正数延后，负数推前
dateTime.getPreStrDate = function(dateStr, pro) {
	if (!pro) pro = 0;
	var date = dateTime.getAppointDate(dateStr);
	var time = date.getTime() + pro * 86400000;
	return dateTime.getDate(new Date(time));
}
//计算两个字符日期相差天数
dateTime.getDiffDay = function(startDate, endDate) {
	if (!startDate || startDate.length < 10) return unkn;
	if (!endDate || endDate.length < 10) return unkn;
	startDate = startDate.substring(0, 10);
	endDate = endDate.substring(0, 10);
	var s = ((new Date(endDate.replace(/-/g, "\/"))) - (new Date(startDate.replace(/-/g, "\/"))));
	var day = s / 86400000;
	return Math.floor(day);
}

// 获取两个日期的绝对值差值
dateTime.getDiffDay2 = function(date_1, date_2) {
	// 计算两个日期之间的差值
	let totalDays, diffDate
	let myDate_1 = Date.parse(date_1)
	let myDate_2 = Date.parse(date_2)
	// 将两个日期都转换为毫秒格式，然后做差
	diffDate = Math.abs(myDate_1 - myDate_2) // 取相差毫秒数的绝对值

	totalDays = Math.floor(diffDate / (1000 * 3600 * 24)) // 向下取整

	return totalDays // 相差的天数
}
// 获取两个日期的 正 负差值   
dateTime.getDiffDay3 = function(date_1, date_2) {
	// 计算两个日期之间的差值
	let totalDays, diffDate
	let myDate_1 = Date.parse(date_1)
	let myDate_2 = Date.parse(date_2)
	// 将两个日期都转换为毫秒格式，然后做差
	diffDate = myDate_1 - myDate_2// 取相差毫秒数的绝对值

	totalDays = Math.ceil(diffDate / (1000 * 3600 * 24)) // 向下取整

	return totalDays // 相差的天数
}

//获取指定时间，默认返回当前时间，如果不想有默认值，mode=true
dateTime.getAppointDate = function(str, mode) {
	mode = mode === false ? false : true;
	return strNotNull(str) ? new Date(str) : (mode ? new Date() : unkn);
}
// 获取短时间戳
dateTime.getDate = function(date) {
	var y = date.getFullYear(),
		m = date.getMonth() + 1,
		d = date.getDate();
	var date = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
	return date
};

// 传入日期比较 大小 
dateTime.getDiffSize = function(date_1, date_2){
	if(!date_1 || !date_2){
		return 
	}
	let date1 = new Date(date_1);
	let date2 = new Date(date_2);
	return date1.getTime()>=date2.getTime()
}



var strNotNull = function(str) {
	return typeof(str) == "string" && str != "";
}
// 获取前后或某月的今天日期，传正数则后月份 ， 负数则是前月份
function getMonthDate(date, num) {
	var y = date.getFullYear();
	var m = date.getMonth() + 1;
	var d = date.getDate();
	let fyNum = 0; // 增 减 年份
	let mtNum = 0; //月份
	if (num == 0 || !num) {
		y = date.getFullYear();
		m = date.getMonth() + 1;
		d = date.getDate();
	} else if (num > 0) {
		// 取余数
		mtNum = (num + m) % 12 == 0 ? 12 : (num + m) % 12;
		if (num + m <= 12) {
			fyNum = 0;
		} else {
			if ((num + m) / 12 == parseInt((num + m) / 12)) {
				let num2 = (num + m) / 12;
				if (num2 >= 2) {
					fyNum = num2 - 1;
				}
			} else if ((num + m) / 12 != parseInt((num + m) / 12)) {
				let num3 = parseInt((num + m) / 12);
				if (num3 >= 2) {
					fyNum = num3;
				} else if (num3 == 1) {
					fyNum = 1;
				}
			}
		}
		// 取整数
		m = mtNum
		y = y + fyNum
	} else if (num < 0) {
		// 年份相减等于0 表示上一年的本月份所以年份减1
		if (m + num == 0) {
			mtNum = 12;
			m = mtNum
			y = y - 1;
		} else if (m + num > 0) {
			// 年份相减大于0 表示在本一年内，年份不减，
			mtNum = (m + num) % 12;
			m = mtNum;
		} else if (m + num < 0) {
			// 年份相减小于0 表示之前年份，
			if ((-num) / 12 == parseInt((-num) / 12)) {
				y = y - ((-num) / 12);
				m = m;
			} else if ((-num) / 12 != parseInt((-num) / 12)) {
				mtNum = (12 + (num + m)) % 12 == 0 ? 12 : 12 - ((-(num + m)) % 12);
				y = y - (((-num - m) / 12) <= 1 ? 1 : Math.ceil((-num - m) / 12))
				m = mtNum
			}
		}



	}
	var date = y + "-" + (m < 10 ? "0" + m : m) + "-" + (d < 10 ? "0" + d : d);
	return date
}

// 获取开始日期的时间戳  当天 00:00:00
dateTime.getStartDateTimeStamp = function(day){
 var time = new Date(new Date(day).toLocaleDateString()).getTime()
  return time
}
// 获取结束日期的时间戳  当天 23:59:59的时间戳
dateTime.getEndDateTimeStamp = function(day){
 let limitDay = dateTime.getAppointDate(new Date(day),1);
 var time = new Date(limitDay).getTime() - 1;
  return time
}

// 获取今日 日期 带 时 分 秒 // 获取当前时间转化为2022-04-06 16:32:54 的格式
dateTime.allToDay = function(){
	var myDate = new Date();
	var year = myDate.getFullYear(); //获取当前年
	var mon = myDate.getMonth() + 1; //获取当前月
	var date = myDate.getDate(); //获取当前日
	var hours = myDate.getHours(); //获取当前小时
	var minutes = myDate.getMinutes(); //获取当前分钟
	var seconds = myDate.getSeconds(); //获取当前秒
	var now = year + "-" + (mon<10?'0'+mon:mon) + "-" + (date<10?'0'+date:date) + " " + (hours<10?'0'+hours:hours) + ":" + (minutes<10?'0'+minutes:minutes) + ":" + (seconds<10?'0'+seconds:seconds);
	return now
}

// 计算前后时间  返回 分钟数
dateTime.getDiffMinute = function(prev, next) {
  // var prevTime = dateTime.getSecondByDateStr(prev, 0);
  var time1 = new Date(prev).getTime();
  // var nextTime = dateTime.getSecondByDateStr(next, 0);
  var time2 = new Date(next).getTime();

  var minute = (time2 - time1) / 1000 / 60;

  return minute;
}
// 将2018-01-02 05:15  转换为 格林尼治 :
dateTime.getSecondByDateStr = function(dat, num) {
    if (!num) num = 0;

    var arr = dat && dat.split(' ');
    var ymd = arr[0].split('-');
    var y = parseInt(ymd[0]);
    var m = ymd[1] - 0;
    var d = parseInt(ymd[2]);
    var sfm = arr[1].split(':');
    var s = parseInt(sfm[0]),
      f = parseInt(sfm[1]),
      miao = sfm.length == 3 ? parseInt(sfm[2]) : 0;

    var date = new Date(y, m, d, s, f, miao);
    var time = date.getTime() + (num - 0) * 3600000;
    date = new Date(time);
    return date;
  }

// 处理火车票 返回时间格式错误 20220715 转换为 2022-07-15 目前支持8位短日期
dateTime.zqFormat = function(dat){
	if(!dat){
		return 
	}
	var date = "";
	if(dat.indexOf("-")==-1 && dat.length==8){
		var y = dat.substring(0,4);
		var m = dat.substring(4,6);
		var d = dat.substring(6,8);
		date = y+'-'+m+'-'+d
	}else{
		date = dat
	}
	return date
}
/*
* 
* 	返回指定日期 时间戳
* 	
* */ 

dateTime.getTimeStamp = function(date){
	if(!date){return};
	let getDate = new Date(date);
	return getDate.getTime();
}

dateTime.getDateMing = function(strTime, num) {
	var dat;
	if (strTime && typeof(strTime) == 'string') {
		strTime = strTime.replace(' ', ',');
		strTime = strTime.replace(/-/g, ',');
		strTime = strTime.replace(/:/g, ',');
		strTime = strTime.split(',');
		if (strTime.length >= 3) {
			var dat = new Date(strTime[0] - 0, strTime[1] - 1, strTime[2] - 0, (strTime[3] || 0) - 0, (strTime[4] ||
					0) - 0,
				(strTime[5] || 0) - 0);
		} else {
			dat = new Date();
		}
	} else {
		dat = new Date()
	}

	(!num) && (num = 0);
	dat = dat.getTime() + 24 * 60 * 60 * 1000 * num;
	dat = new Date(dat);
	var y = dat.getFullYear();
	var m = dat.getMonth() + 1;
	var d = dat.getDate();
	if (d < 10) {
		d = "0" + d
	}
	if (m < 10) {
		m = "0" + m
	};
	return y + "-" + m + "-" + d;
}

export default dateTime
