
var company = {
		list: [
			{
				"compId": "3c5b02ca9a7e4380a9257b1d8349f8f5", //总公司id
				"corpName":'空港嘉华', //简称
				"officalPhone": "(010)-64581922", //电话
				"corpFullName": '北京空港嘉华航空服务有限公司', //全称
				"industryName": '航空服务有限公司', //业务
				"address": '北京市朝阳区首都机场西平街甲8号', //地址
				"email":'konggangjiahua@163.com', //邮箱
				"english":'Beijing Airport Jiahua Aviation Service Co.,Ltd',
				"postalCode":100621, //邮编
				"fax":'(010)-64581105', //传真
				"image":require('@/assets/image/kg-logo.png'),
			},
			{
				"compId": "d5ee3ab10dee43a1beecb122a0ef7455",
				"corpName":'鹏翔航空',
				"officalPhone":"0535-6108899",
				"corpFullName":'烟台鹏翔航空票务代理有限公司',
				"industryName": '航空票务代理有限公司',
				"address": '烟台市开发区泰山路106号7-2',
				"email":'pengxianghk@163.com',
				"english":'Yantai Pengxiang airline ticket agency Co., Ltd',
				"postalCode":264006,
				"fax":'(010)-64581105', //传真
				"image":require('@/assets/image/pxhk/logo.png'),
			},
			// {
			// 	"compId": "68c61e712ee641afa5ae77335e33e31c",
			// 	"corpName":'希安康商旅',
			// 	"officalPhone":"+86(0)10-80429920",
			// 	"corpFullName":'希安康国际旅行社（北京）有限公司',
			// 	"industryName": '国际旅行社（北京）有限公司',
			// 	"address": '',
			// 	"email":'',
			// 	"english":'Xi'ankang International Travel Agency (Beijing) Co., Ltd',
			// 	"postalCode":,
			// 	"fax":'', //传真
			// 	"image":require('@/assets/image/pxhk/logo.png'),
			// }
		]
}; 
	
	
export default company