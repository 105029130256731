// 用于初始化

var tkMap = {
	// 机票查询页
	tkQuery: {
		bookStyle:"1", // 1. 单订 2. 出差申请单模式
		'travelType': '1', // 1.单程 2. 往返
		"tripType": 1, // 因公 因私
		"tkType": 0, //  1： G、政采 、 0： C 正常
		//城市
		"dep": '', //  出发城市
		"depszm": '', //  出发城市三字码
		"depCityType": 1, //  默认为查询国内机票
		depInputName: "", // 
		"arr": '', //  到达城市
		"arrszm": '', //  到达城市三字码
		"arrCityType": 1, //
		arrInputName: "", // 
		// shc加上的 首页城市选择
		depCityId: '',// 出发城市ID
		arrCityId: '', // 到达城市ID
		
		// 日期
		"depDate": '', //   出发日期
		"arrDate": '', //   到达日期
		minDay:"",
		maxDay:"",
		// 出差申请单参数
		ccsqdNo: '',
		ccsqdId: '',
		routeId: '',
		// 差旅匹配参数
		'empRank': 0, //   职级
		'useNameclbz': '', // 本次预订使用谁的差旅标准
		'clbzText': '', // 差旅标准   现已转移至航班列表进行查询  具体的差旅政策
		'minEmpRankEmpId':'', // 当前员工差旅标准的职级
		'gkfsRole': '0', //  10999  是否按出差申请单进行管控   默认  0：不管控 1：按申请单预算管控 2：按差旅标准管控
		// 当前出行人 因公模式、头等公务舱标准
		isDomesticBusinessClass: 1,// 国内公务
		isDomesticFirstClass: 1, // 国内头等
		isDomesticHighClass: 1, // 国内高端经济
		isInternationalBusinessClass: 1,
		isInternationalFirstClass: 1,
		isInternationalHighClass: 1,
		tripType1CanBookXieYi:0, // 因私是否可以预订协议价 1.可以预订 0不可预订
		tripType1CanBookAirlineCompany:"", // 因私可以预订协议价的航司！
		// 页面控制
	
		sortType:"departTime-1", //  默认排序类型 去程查询
		sortTypeBack:"departTime-1", // 返程查询 排序类型
		isAutoQuery: 0, // 是否自动查询   1  自动查询  0 不查询
		loadingFlightList: 0,  // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
		isQueryTravelType:'1',  // 当前正在查询去程、还是返程
		
		
	
	},
	chooseCcsqd: {}, // 选择出差申请单
	flightList: [],
	flightMap: {},
	chooseFlight: null,
	
	
	flightListBack:[],
	flightMapBack:{},
	chooseFlightBack:null,
	nhrh:0, //当前服务商是否开启 南航一键入会 1开启， 0关闭
	
	cxrList: [],
	lsCxrList:[], //出行人临时数据
	tkEdit: {
		project: {
			id: '', // 项目id
			projectCode: "", // 项目名称
			projectName: '' // 项目代码
		},
		spgz: {
			gzId: '', // 审批规则id
			gzmc: '', // 审批规则名称
			gzdm: '' // 审批规则代码
		},
		linker: {
			linkEmail: '', // (string, optional): 联系邮箱 ,
			linkMan: '', // (string, optional): 联系人 ,
			linkPhone: '', // (string, optional): 联系电话 ,
		},
		bxList: [], // 选择的报险产品
		"bankName": "", //  预算单位 gpValidType为 2 时必填
		"budgetUnit": "", //  预算单位 gpValidType为 1 时必填 
		"gpValidType": '1', //GP票验证类型 1预算单位 2开户行 ,
		isShowChooseZuo:false, // 是否选座
	
	}, // 机票填写页
	
	tkFilterMap: {
		// 舱位类型map
		cabinTypeMap:{},
		// 航司map
		airlineMap:{},
		// 起抵时段
		depTimeMap:{},
		arrTimeMap:{},
		// 起飞机场
		depAirportMap:{},
		// 降落机场
		arrAirportMap:{},
		// 计划机型
		planeMap:{},
		// 直飞、经停
		stopNumMap:{},
	},
	
	tkFilterMapBack: {
		// 舱位类型map
		cabinTypeMap:{},
		// 航司map
		airlineMap:{},
		// 起抵时段
		depTimeMap:{},
		arrTimeMap:{},
		// 起飞机场
		depAirportMap:{},
		// 降落机场
		arrAirportMap:{},
		// 计划机型
		planeMap:{},
		// 直飞、经停
		stopNumMap:{},
	}
	
	
	
};


export default tkMap