<template>
	<div class="linker">
		<el-card >
			<h1 slot="header" class="text-left">联系人</h1>
			<el-form>
				<div class="linker-content">
					<div class="width340">
						<el-form-item label="联系人姓名"  >
						    <el-input  
								v-model="linker.linkMan" 
								placeholder="请填写联系人姓名"  
								@change="getLinker"></el-input>
						  </el-form-item>
					</div>
					<div class="width340">
						<el-form-item label="联系人电话"  prop="phone">
						    <el-input  
								v-model="linker.linkPhone" 
								placeholder="用于接收订票信息及航班实时信息"  
								@change="getLinker"></el-input>
						  </el-form-item>
					</div>
					<div class="width340">
						<el-form-item label="联系人邮箱" prop="email">
						    <el-input  
								v-model="linker.linkEmail"
								placeholder="请填写联系人邮箱" 
								@change="getLinker"></el-input>
						  </el-form-item>
					</div>
				</div>
				
				  
					
			</el-form>
			
		</el-card>
	</div>
</template>

<script>
	export default{
		data(){
			return {
				linker:{
					linkMan:"",
					linkPhone:"",
					linkEmail:"",
				}
			}
		},
		props:{
			defaultLinker:{
				type:[Object],
				default (){
					return {
						
					}
				}
			},
			isShowEmail:Boolean,
		},
		mounted(){
			this.linker = {
				linkMan:this.defaultLinker.linkMan,
				linkPhone:this.defaultLinker.linkPhone,
				linkEmail:this.defaultLinker.linkEmail,
			}
		},
		methods:{
			getLinker(){
				this.$emit('getLinker',this.linker)
			}
		}
	}
</script>

<style scoped lang="scss">
	
	.linker{
		font-family: '微软雅黑';
		margin-top: 20px;
		
		/deep/ .el-card__body{
			padding:30px;
		}
			
		/deep/ .el-form-item__label{
			font-size: 12px;
			font-weight: 400;
			color: #748197;
		}
		/deep/ .el-input__inner{
			font-size:15px;
			font-weight: 600;
			color: #223344;
		}
		/deep/ .el-input__inner{
			border: 0;
			padding: 0;
			border-radius: 0;
			border-bottom: 1px solid #C4CDDD;
			font-size: 15px;
		}
		.linker-content{
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.width340{
				width: 340px;
			}
		}
		
		
	}
</style>
