<template>
	<div class="t-main">
		<h1 class="textLeft">{{title}}</h1>
	</div>
</template>

<script>
	export default{
		name: "Title",
		props: {
			title: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.t-main{
		.textLeft{
			text-align: left;
		}
	}
</style>
