<template>
	<div class="sd-main">
		<el-dialog :title="configData.corpName+showType[params]" :visible.sync="visible"
			:before-close="close">
			<div class="content" v-if="params==1 || params==10">
				<h3>服务条款</h3>
				<p class="font13 black line-height24">
					{{configData.corpName}}所提供的各项服务和内容的所有权和运作权均归{{configData.corpFullName}}及其关联公司（以下简称“{{configData.corpName}}”）所有。如果您在本网站、{{configData.corpName}}关联公司网站或其他{{configData.corpName}}提供的移动应用或软件上（以下简称“{{configData.corpName}}”），访问、预定或使用我们的产品或服务（以上统称为“服务”），您便接受了以下服务协议，请仔细阅读以下内容。如果您不同意以下任何内容，请立刻停止访问本网站或使用本网站服务。
				</p>
				<h5 class="font15 black marginTop30">一、协议总则</h5>
				<p class="font13 black line-height24">
					1.本协议内容包括协议正文、{{configData.corpName}}子频道各单项服务协议及其他{{configData.corpName}}已经发布的或将来可能发布的各类规则，包括但不限于
					隐私政策、 免责声明、 知识产权声明、 权利声明、 旅游度假预订须知、 {{configData.corpName}}旅图用户协议、
					{{configData.corpName}}网个人账户协议等其他协议（“其他条款”）。如果本协议与“其他条款”有不一致之处，则以“其他条款”为准。除另行明确声明外，任何{{configData.corpName}}提供的服务均受本协议约束。
				</p>
				<p class="font13 black line-height24">
					2.{{configData.corpName}}有权根据需要不时地制订、修改本协议及/或各类规则向用户提供基于互联网以及移动网的相关服务，并在本页面及其相应页面进行公布，但不再另行通知您，您应该定期登陆本页面及其他相关页面，了解最新的协议内容。变更后的协议和规则一经在本页面及相关页面公布后，立即自动生效。如您不同意相关变更，应当立即停止访问{{configData.corpName}}网或使用{{configData.corpName}}服务。若您继续使用{{configData.corpName}}服务的，即表示您接受已经修订的协议和规则。
				</p>
				<p class="font13 black line-height24">
					3.若您作为{{configData.corpName}}的关联公司或合作公司的用户登陆{{configData.corpName}}平台，访问{{configData.corpName}}网站或使用{{configData.corpName}}服务，即视为您同意本协议的所有条款及{{configData.corpName}}公布的其他规则、说明和操作指引。
				</p>
				<h5 class="font15 black marginTop30">二、用户权利</h5>
				<p class="font13 black line-height24">
					在您完成注册申请手续后，意味着您已获得{{configData.corpName}}账户（用户名）的使用权。您应提供及时、详尽及准确的个人资料，并不断更新注册资料，符合及时、详尽准确的要求。您应妥善保管您的账户（用户名）和密码，通过您的账户（用户名）和密码操作或实施的行为，将视为您本人的行为，由您本人承担相应的责任和后果。如果您发现他人不当使用您的账户或有任何其他可能危及您的账户安全的情形时，您应当立即以书面、有效方式通知{{configData.corpName}}，要求{{configData.corpName}}暂停相关服务。在此，您理解{{configData.corpName}}对您的请求采取行动需要合理时间，{{configData.corpName}}对在采取行动前已经产生的后果（包括但不限于您的任何损失）不承担任何责任。
				</p>
				<h5 class="font15 black marginTop30">三、使用规则</h5>
				<p class="font13 black line-height24">
					1.您在使用{{configData.corpName}}服务时，必须遵守中华人民共和国相关法律法规的规定，您应同意将不会利用该服务进行任何违法或不正当的活动，包括但不限于下列行为:</p>
				<p class="font13 black line-height24">1.1上载、展示、张贴、传播或以其它方式传送含有下列内容之一的信息：</p>
				<p class="font13 black line-height24">1) 反对宪法所确定的基本原则的；</p>
				<p class="font13 black line-height24">2) 危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
				<p class="font13 black line-height24">3) 损害国家荣誉和利益的；</p>
				<p class="font13 black line-height24">4) 煽动民族仇恨、民族歧视、破坏民族团结的；</p>
				<p class="font13 black line-height24">5) 破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
				<p class="font13 black line-height24">6) 散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
				<p class="font13 black line-height24">7) 散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
				<p class="font13 black line-height24">8) 侮辱或者诽谤他人，侵害他人合法权利的；</p>
				<p class="font13 black line-height24">9) 含有虚假、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
				<p class="font13 black line-height24">10) 含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的。</p>
				<p class="font13 black line-height24">1.2为任何非法目的而使用网络服务系统。</p>
				<p class="font13 black line-height24">1.3利用{{configData.corpName}}网络服务从事以下活动：</p>
				<p class="font13 black line-height24">1) 未经允许，进入计算机信息网络或者使用计算机信息网络资源的；
					未经允许，对计算机信息网络功能进行删除、修改或者增加的；未经允许，对进入计算机信息网络中存储、处理或者传输的数据和应用程序进行删除、修改或者增加的；故意制作、传播计算机病毒等破坏性程序的；其他危害计算机信息网络安全的行为。
				</p>
				<p class="font13 black line-height24">2)
					对{{configData.corpName}}网站上的任何数据作商业性利用，包括但不限于在未经{{configData.corpName}}事先书面同意的情况下，以复制、传播等任何方式使用{{configData.corpName}}网站上展示的资料。
				</p>
				<p class="font13 black line-height24">3)
					使用任何装置、软件或例行程序等其他方式干预或试图干预{{configData.corpName}}网站的正常运作或正在{{configData.corpName}}网站上进行的任何交易、活动，或采取任何将导致不合理的庞大数据负载加诸{{configData.corpName}}网络设备的行动。
				</p>
				<p class="font13 black line-height24">4)
					违反诚实信用原则的不正当竞争行为，或恶意下订单或虚假交易等其他恶意扰乱{{configData.corpName}}交易秩序的行为。</p>
				<p class="font13 black line-height24">5) 与网上交易无关的其他行为。</p>
				<p class="font13 black line-height24">
					2.对于您违反本服务协议，导致或产生的任何第三方主张的任何索赔、要求或损失，包括合理的律师费，您同意赔偿{{configData.corpName}}与合作公司，并使之免受损害。同时，{{configData.corpName}}有权视您的行为性质，采取包括但不限于删除您发布信息内容、暂停使用许可、终止服务、限制使用、回收{{configData.corpName}}账号、追究法律责任等措施，因{{configData.corpName}}采取上述合理措施给您造成的损失，{{configData.corpName}}不承担任何责任。对恶意注册{{configData.corpName}}账号或利用{{configData.corpName}}账号进行违法活动、捣乱、骚扰、欺骗其他用户以及其他违反本协议的行为，{{configData.corpName}}有权回收其账号。同时，{{configData.corpName}}会视司法部门的要求，协助调查。
				</p>
				<p class="font13 black line-height24">
					3.您须对自己在使用{{configData.corpName}}网络服务过程中的行为承担法律责任，包括但不限于：对受到侵害者进行赔偿，以及在{{configData.corpName}}在先承担了因您的行为导致的行政处罚或侵权损害赔偿责任后，您应给予{{configData.corpName}}等额的赔偿。
				</p>
				<h5 class="font15 black marginTop30">四、服务的变更、中断或终止</h5>
				<p class="font13 black line-height24">
					1.您完全理解并同意，本服务涉及到互联网及移动通讯等服务，可能会受到各个环节不稳定因素的影响。因此任何因不可抗力、计算机病毒或黑客攻击、系统不稳定、用户所在位置、用户关机、GSM网络、互联网络、通信线路等其他{{configData.corpName}}无法预测或控制的原因，造成的服务中断、取消或终止的风险。您须自行承担以上风险，{{configData.corpName}}对服务之及时性、安全性、准确性不做任何保证。
				</p>
				<p class="font13 black line-height24">
					2.{{configData.corpName}}需要定期或不定期地对提供网络服务的平台或相关的设备进行检修或者维护，如因此类情况而造成网络服务（包括收费网络服务）在合理时间内的中断，{{configData.corpName}}无需为此承担任何责任。{{configData.corpName}}保留不经事先通知为维修保养、升级或其它目的暂停全部或部分的网络服务的权利。
				</p>
				<p class="font13 black line-height24">
					3.您完全理解并同意，除本服务协议另有规定外，鉴于网络服务的特殊性，{{configData.corpName}}有权随时变更、中断或终止部分或全部的网络服务，且无需通知您，也无需对您或任何第三方承担任何责任。
				</p>
				<h5 class="font15 black marginTop30">五、知识产权和其他合法权益</h5>
				<p class="font13 black line-height24">1.知识产权权属对于您通过{{configData.corpName}}
					网或其他{{configData.corpName}}移动终端而上传的任何在公开区域可获取的并受著作权保护的内容，用户应对该等内容的真实性、合法性负责，保证对该等内容拥有完整的、无瑕疵的所有权和知识产权或拥有完整的授权，并不存在任何侵犯第三方合法权益的情形，包括但不限于著作权、肖像权、商标权、专利权、企业名称权、商号权、商业秘密、个人隐私、合同权利等权利。所有因用户非法上传内容而给任何第三方或{{configData.corpName}}造成的损害均由用户个人承担全部的责任，{{configData.corpName}}不承担任何责任，且{{configData.corpName}}有义务配合第三方、司法机关或行政机关完成相关的取证，并根据法律、主管部门或司法部门的要求将用户注册信息给予披露。对于您通过互联网或移动终端等设备访问{{configData.corpName}}及其关联公司网站时发表的任何形式的文字、图片等知识产权信息，您在此授权并同意将其著作财产权，包括并不限于：复制权、发行权、出租权、展览权、表演权、放映权、广播权、信息网络传播权、摄制权、改编权、翻译权、汇编权等，以及应当由著作权人享有的其他可转让权利，无偿独家转让给{{configData.corpName}}所有，许可{{configData.corpName}}有权利就任何主体侵权而单独提起诉讼，并获得全部赔偿。本协议已经构成《著作权法》第二十五条所规定的书面协议，其效力及于用户通过互联网或移动终端等设备访问{{configData.corpName}}发布的任何受著作权法保护的作品内容，无论该内容形成于本协议签订前还是本协议签订后。对于您提供的其他资料及数据信息，您授予{{configData.corpName}}及其关联公司独家的、全球通用的、永久的、免费的许可使用权利
					(并有权在多个层面对该权利进行再授权)。此外，{{configData.corpName}}及其关联公司有权(全部或部份地)
					使用、复制、修订、改写、发布、翻译、分发、执行和展示您的全部资料数据（包括但不限于注册资料、交易行为数据及全部展示于{{configData.corpName}}平台的各类信息）或制作其派生作品，并以现在已知或日后开发的任何形式、媒体或技术，将上述信息纳入{{configData.corpName}}作品内。用户浏览、复制、打印和传播{{configData.corpName}}其他用户上传到{{configData.corpName}}网站的内容，应保证仅用于个人欣赏之目的，不得用于商业目的，不得侵犯权利人的合法权益以及{{configData.corpName}}的合法权益和商业利益。任何用户违反此条规定的，{{configData.corpName}}均有权以自身名义利用法律手段寻求权利救济或据本协议追究您的违约责任。
				</p>
				<p class="font13 black line-height24">2.{{configData.corpName}}专属权利</p>
				<p class="font13 black line-height24">
					2.1除明显归属于合作伙伴、第三方所有的信息资料外，{{configData.corpName}}拥有网络服务内所有内容，包括但不限于文字、图片、图形、表格、动画、程序、软件等单独或组合的版权。任何被授权的浏览、复制、打印和传播属于本网站内的内容必须符合以下条件：
				</p>
				<p class="font13 black line-height24">1) 所有的资料和图象均以获得信息为目的；</p>
				<p class="font13 black line-height24">2) 所有的资料和图象均不得用于商业目的；</p>
				<p class="font13 black line-height24">3) 所有的资料、图象及其任何部分都必须包括此版权声明。</p>
				<p class="font13 black line-height24">
					未经{{configData.corpName}}许可，任何人不得擅自，包括但不限于以非法的方式复制、传播、展示、镜像、上载、下载使用。否则，{{configData.corpName}}将依法追究法律责任。
				</p>
				<p class="font13 black line-height24">
					2.2{{configData.corpName}}为提供网络服务而自行开发的软件，包括不限于无线客户端应用等，拥有完整的知识产权。{{configData.corpName}}在此授予您个人非独家、不可转让、可撤销的，并通过在一部拥有所有权或使用权的手机或计算机上使用{{configData.corpName}}软件的权利，且该使用仅限于个人非商业性使用之合法目的。
				</p>
				<p class="font13 black line-height24">
					{{configData.corpName}}有权对该等软件进行时不时的修订、扩展、升级等更新措施，而无需提前通知用户，但您有权选择是否使用更新后的软件
				</p>
				<p class="font13 black line-height24">您应当保证合法使用该等软件，任何用户不得对该等软件进行如下违法行为：</p>
				<p class="font13 black line-height24">1) 开展反向工程、反向编译或反汇编，或以其他方式发现其原始编码，以及实施任何涉嫌侵害著作权等其他知识产权的行为；</p>
				<p class="font13 black line-height24">2) 以出租、租赁、销售、转授权、分配或其他任何方式向第三方转让该等软件或利用该等软件为任何第三方提供相似服务；</p>
				<p class="font13 black line-height24">3) 任何复制该等软件的行为；</p>
				<p class="font13 black line-height24">4) 以移除、规避、破坏、损害或其他任何方式干扰该等软件的安全功能；</p>
				<p class="font13 black line-height24">5) 以不正当手段取消该等软件上权利声明或权利通知的； </p>
				<p class="font13 black line-height24">6) 其他违反法律规定的行为。</p>
				<p class="font13 black line-height24">
					2.3“{{configData.corpName}}”和{{configData.corpName}}网络服务LOGO等为{{configData.corpName}}的注册商标，受法律的保护。任何用户不得侵犯{{configData.corpName}}的注册商标权。
				</p>
				<h5 class="font15 black marginTop30">六、其他</h5>
				<p class="font13 black line-height24">1.本协议的订立、执行和解释及争议的解决均应适用中华人民共和国法律。</p>
				<p class="font13 black line-height24">
					2.如双方就本协议内容或其执行发生任何争议，双方应尽量友好协商解决；协商不成时，任何一方均可向北京趣拿信息技术有限公司所在地的人民法院提起诉讼。</p>
				<p class="font13 black line-height24">3.{{configData.corpName}}未行使或执行本服务协议任何权利或规定，不构成对前述权利或权利之放弃。 </p>
				<p class="font13 black line-height24">4.如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。</p>
				<p class="font13 black line-height24">更新日期：2021-10-25</p>
			</div>
			<div class="content" v-if="params==2 || params==10">
				<h4>隐私条款</h4>
				<p class="font13 black line-height24">
					欢迎您访问{{configData.corpName}}！{{configData.corpName}}提醒您：{{configData.corpName}}由{{configData.corpFullName}}运营，涉及具体产品服务的，将由有资质的服务商提供。如果用户在本网站、{{configData.corpName}}关联公司网站或其他{{configData.corpName}}提供的移动应用或软件上（以下简称“{{configData.corpName}}），访问、预定或使用{{configData.corpName}}的产品或服务（以上统称为“服务”），便视为用户接受了以下隐私政策，请您仔细阅读以下内容，尤其是以下加粗字体。如果您不同意以下任何内容，请立刻停止访问/使用本网站或其他任何移动应用或软件。本隐私政策中提及的“{{configData.corpName}}”以及“我们”均指{{configData.corpName}}，“用户”以及“您”均指自愿接受本隐私政策的用户。
				</p>
				<h5 class="font15 black marginTop30">1、隐私政策的确认和接纳</h5>
				<p class="font13 black line-height24">
					尊重用户个人隐私是{{configData.corpName}}的一项基本政策。{{configData.corpName}}是将有资质的酒店、机票代理机构、等提供的服务信息汇集于{{configData.corpName}}供用户查阅，同时帮助用户通过{{configData.corpName}}与上述酒店、机票代理机构等联系并预订相关服务项目。用户通过完成注册程序并点击“完成注册”的按钮，表示用户明确知晓本隐私政策所列事实，并与{{configData.corpFullName}}达成协议并接受所有的服务条款。
				</p>
				<p class="font13 black line-height24">
					我们十分注重保护用户的个人信息及隐私安全，我们理解您通过网络向我们提供信息是建立在对我们信任的基础上，我们重视这种信任，也深知隐私对您的重要性，并尽最大努力全力保护您的隐私。</p>
				<h5 class="font15 black marginTop30">2、信息收集</h5>
				<p class="font13 black line-height24">
					（1）为了更好地为您提供服务，{{configData.corpName}}将遵循“合理、相关、必要”原则，且以您同意为前提收集信息，不会收集法律法规禁止收集的信息。考虑到{{configData.corpName}}会员服务的重要性，用户同意，{{configData.corpName}}将会收集您在{{configData.corpName}}上输入的或者以其他方式提供给我们的所有信息：
				</p>
				<p class="font13 black line-height24">在您注册为{{configData.corpName}}会员时，需提供姓名、性别、生日、手机号、邮箱等信息。</p>
				<p class="font13 black line-height24">在您后续更新个人信息时，需符合及时、详尽、准确的要求。 </p>
				<p class="font13 black line-height24">在您预订机票或火车票时，需提供乘客姓名、性别、生日、证件号以及联系 人姓名、手机号、邮箱及配送地址等信息。</p>
				<p class="font13 black line-height24">在您预订酒店、景点门票时，需提供入住人姓名、联系人姓名、手机号、邮件等信息。</p>
				<p class="font13 black line-height24">在您购买产品进行支付结算时，需提供银行卡卡号、银行预留手机号、或者信用卡卡号、持卡人姓名、到期日期等信息。</p>
				<p class="font13 black line-height24">
					我们也可能了解您的旅行计划、风格和喜好，包括膳食要求、出行/入离时间、座位选择、票务选择、保险选择以及{{configData.corpName}}提供其他服务（如旅游、用车、门票、攻略等）所需要您提供的个人信息。
				</p>
				<p class="font13 black line-height24">
					（2）您可以通过{{configData.corpName}}为其他人预订，您需要提交该旅客的个人信息，向我们提供该旅客的个人信息之前，您确保您已经取得本人的同意，并确保其已知晓并接受本隐私政策。
				</p>

				<p class="font13 black line-height24">
					（3）我们将在您使用{{configData.corpName}}服务过程中，自动收集您的个人信息：日志信息，指您使用我们的服务时，系统可能通过cookies、web
					beacon或其他方式自动采集的信息，包括：</p>

				<p class="font13 black line-height24">
					（4）我们会从关联公司、业务合作伙伴来源获得您的相关信息。例如当您通过我们关联公司、业务合作伙伴网站及其移动应用软件等预订时，您向其提供的预订信息可能会转交给我们，以便我们处理您的订单，确保您顺利预订。又如，我们允许您使用社交媒体帐号关联我们的帐号进行登录，在您同意的情况下（您授权给该社交平台），您的相关个人信息会通过社交平台分享给我们。
				</p>

				<h5 class="font15 black marginTop30">3、信息使用</h5>
				<p class="font13 black line-height24">
					（1）一般来说，{{configData.corpName}}出于以下目的收集、使用您的个人信息：向您提供服务：我们使用您的个人数据完成并管理您的网上预订。另外，我们也会通过关联公司、业务合作伙伴和第三方为您提供相关的产品和服务。
				</p>
				<p class="font13 black line-height24">
					帐号管理：您可以通过{{configData.corpName}}创建帐号，我们将使用您所提供的信息来管理您的帐号，并为您提供一系列的实用功能，您可以使用您的帐号进行多种操作，如管理订单、调整个人设置、添加常用旅客信息、查看历史订单信息、订单评价、支付管理等。
				</p>
				<p class="font13 black line-height24">答复您的问询和请求：我们提供7*24小时及多语言的国际化客户服务，以便在您需要的时候提供帮助。</p>
				<p class="font13 black line-height24">
					营销活动：我们也会使用您的信息进行正当合法的营销活动，如向您发送旅行相关的产品或者服务的最新消息、向您提供您可能感兴趣的个性化推荐及其他推广活动信息。</p>
				<p class="font13 black line-height24">
					与您联系：回复及处理您所提出的疑问或要求、发送与订单相关的信息（如订单提交成功提示、未完成订单的提醒您继续等）、我们也可能会向您发送问卷调查或邀请您对我们的服务提供反馈等。</p>
				<p class="font13 black line-height24">市场调研：我们有时会邀请您参与市场调查，以衡量您对我们的产品、服务和网站的兴趣。</p>
				<p class="font13 black line-height24">提升服务的安全性和可靠性：我们可能会检测和预防欺诈行为、不法活动，将您的个人数据用于风险评估和安全目的。</p>
				<p class="font13 black line-height24">
					数据分析：我们可能将个人数据用于分析，以便让我们能够了解您所在的位置、偏好和人口统计信息，或与其他来源（包括第三方）的数据相匹配，从而开发我们的产品、服务或营销计划，改进我们的服务。</p>
				<p class="font13 black line-height24">日常运营：包括但不限于订单管理、客户验证、技术支持、网络维护、故障排除、内部行政事务及内部政策和程序、生成内部报告等。</p>
				<p class="font13 black line-height24">
					电话监测：您接听和拨打我们的客服电话可能会被录音，我们可能会使用通话录音来监控我们的客服服务质量，检查您所提供的信息的准确性以防止欺诈，或为了我们内部人员培训的目的。录音在被保留一段时间后会自动删除，除非由于合规要求或合法利益需要为场景的保留。
				</p>
				<p class="font13 black line-height24">履行义务：处理相关政策下发生的保险索赔和付款，处理支付给合作伙伴的佣金或对服务合作伙伴造成的损失提起索赔或收回付款等。</p>
				<p class="font13 black line-height24">
					法律目的：我们可能需要使用您的信息来处理和解决法律纠纷，或遵循对我们具有约束力的任何法律法规或监管机构颁发的文件规定，以配合国家部门或监管机构调查和遵从法律法规的目的。</p>
				<p class="font13 black line-height24">此外，我们可能会出于其他目的收集、使用和披露您的个人信息，并通过修订本声明的形式告知您。</p>
				<p class="font13 black line-height24">
					（2）您在享受{{configData.corpName}}提供的各项服务的同时，授权并同意接受{{configData.corpName}}向您的电子邮件、手机、通信地址等发送商业信息，包括不限于最新的{{configData.corpName}}产品信息、促销信息等。若您选择不接受{{configData.corpName}}提供的各类信息服务，您可以按照{{configData.corpName}}提供的相应设置拒绝该类信息服务。
				</p>
				<p class="font13 black line-height24">（3）您充分知晓，以下情形中我们使用个人信息无需征得您的授权同意：</p>
				<p class="font13 black line-height24">与国家安全、国防安全有关的；</p>
				<p class="font13 black line-height24">与公共安全、公共卫生、重大公共利益有关的</p>
				<p class="font13 black line-height24">与犯罪侦查、起诉、审判和判决执行等有关的；</p>
				<p class="font13 black line-height24">出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
				<p class="font13 black line-height24">所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
				<p class="font13 black line-height24">从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；</p>
				<p class="font13 black line-height24">根据您的要求签订合同所必需的；</p>
				<p class="font13 black line-height24">用于维护所提供的产品与/或服务的安全稳定运行所必需的，例如发现、处置产品与/或服务的故障；</p>
				<p class="font13 black line-height24">为合法的新闻报道所必需的；</p>
				<p class="font13 black line-height24">学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
				</p>
				<p class="font13 black line-height24">法律法规规定的其他情形。</p>
				<h5 class="font15 black marginTop30">4、信息共享、转让和公开披露</h5>
				<p class="font13 black line-height24">
					（1）我们可能会向合作伙伴等第三方共享您的订单信息、账户信息、设备信息以及位置信息，以保障为您提供的服务顺利完成。但我们仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享提供服务所必要的个人信息。我们的合作伙伴包括以下类型（包含中国境内和中国境外实体）：
				</p>
				<p class="font13 black line-height24">
					供应商：包括但不限于为了满足您预订需求的酒店、航空公司、邮轮、汽车租赁、旅行社和活动提供商和代理商。这些供应商可能根据需要与您联系，以完成旅行产品或服务。</p>
				<p class="font13 black line-height24">
					金融机构和第三方支付机构：当您预订订单、申请退款、购买保险时，我们会与金融机构或第三方支付机构共享特定的订单信息，当我们认为用于欺诈检测和预防目的实属必要时，我们将进一步和相关金融机构共享其他必要信息，如IP地址等。
				</p>
				<p class="font13 black line-height24">业务合作伙伴：我们可能与合作伙伴一起为您提供产品或者服务，包括快递业务、通讯服务、客户服务、市场推广、广告投放等。</p>
				<p class="font13 black line-height24">
					关联公司：我们可能会与我们的关联公司共享您的个人信息，使我们能够向您提供与旅行相关的或者其他产品及服务的信息，他们会采取不低于本隐私政策同等严格的保护措施。</p>
				<p class="font13 black line-height24">
					对于我们与之共享个人信息的公司、组织我们会与其签署严格的保密协定，要求他们按照本隐私政策以及我们其他相关的保密和安全措施来处理个人信息。我们的合作伙伴无权将共享的个人信息用于任何其他用途，如要改变个人信息的处理目的，将再次征求您的授权同意。
				</p>
				<p class="font13 black line-height24">（2）信息转让</p>
				<p class="font13 black line-height24">我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：</p>
				<p class="font13 black line-height24">事先获得您的明确同意或授权；</p>
				<p class="font13 black line-height24">根据适用的法律法规、法律程序的要求、强制性的行政或司法要求；</p>
				<p class="font13 black line-height24">
					在涉及合并、收购、资产转让或类似的交易时，如涉及到个人信息转让，我们会要求新的持有您个人信息的公司、组织继续受本隐私政策的约束，否则,我们将要求该公司、组织重新向您征求授权同意。</p>
				<p class="font13 black line-height24">（3）公开披露</p>
				<p class="font13 black line-height24">我们仅会在以下情形，公开披露您的个人信息：</p>
				<p class="font13 black line-height24">根据您的需求，在您明确同意的披露方式下披露您所指定的个人信息；</p>
				<p class="font13 black line-height24">
					根据法律、法规的要求、强制性的行政执法或司法要求所必须提供您个人信息的情况下，我们可能会依据所要求的个人信息类型和披露方式公开披露您的个人信息。在符合法律法规的前提下，当我们收到上述披露信息的请求时，我们会要求必须出具与之相应的法律文件，如传票或调查函。
				</p>
				<h5 class="font15 black marginTop30">5、信息保存及跨境传输</h5>
				<p class="font13 black line-height24">（1）您的个人信息我们将保存至您账号注销之日后的三个月，除非需要延长保留期或受到法律或者有权机关的允许或协助有权机关进行查询。
				</p>
				<p class="font13 black line-height24">
					（2）如我们停止运营{{configData.corpName}}产品或服务，我们将及时停止继续收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，对所持有的个人信息进行删除或匿名化处理。
				</p>
				<p class="font13 black line-height24">（3）我们在中华人民共和国境内运营中收集和产生的个人信息，存储在中国境内，以下情形除外：</p>
				<p class="font13 black line-height24">法律法规有明确规定；</p>
				<p class="font13 black line-height24">获得您的明确授权；</p>
				<p class="font13 black line-height24">您通过互联网进行跨境交易等个人主动行为。</p>
				<h5 class="font15 black marginTop30">6、Cookie的使用</h5>
				<p class="font13 black line-height24">
					Cookie是网页服务器放在您访问设备上的文本文件，会帮助您在后续访问时调用信息，简化记录您填写个人信息的流程。您有权接受或拒绝Cookie，如果浏览器自动接收Cookie，您可以根据自己的需要修改浏览器的设置以拒绝Cookie。请注意，如果您选择拒绝Cookie，那么您可能无法完全体验{{configData.corpName}}提供的服务。
				</p>
				<h5 class="font15 black marginTop30">7、个人敏感信息提示</h5>
				<p class="font13 black line-height24">
					某些特殊的个人信息可能被认为是个人敏感信息，例如您的种族、宗教、个人健康和医疗信息等，这些个人敏感信息将受到严格保护。{{configData.corpName}}隐私政策在此提醒您，您在使用{{configData.corpName}}为您提供的产品及服务中所上传或发布的内容和信息可能会泄露您的个人敏感信息。因此，您需要在使用我们为您提供的产品或服务前谨慎考虑。您同意这些个人敏感信息将按照《{{configData.corpName}}隐私政策》阐明的目的和方式来进行处理。因此，您需要在使用我们为您提供的产品或服务前谨慎考虑。您同意这些个人敏感信息将按照《{{configData.corpName}}隐私政策》阐明的目的和方式来进行处理。
				</p>
				<h5 class="font15 black marginTop30">8、信息安全与保护</h5>
				<p class="font13 black line-height24">（1）{{configData.corpName}}非常重视信息安全，成立了专门的负责团队。
					我们努力为您提供信息保护，采取了合适的管理、技术以及物理安全措施，参照国内外信息安全标准及最佳实践建立了与业务发展相适应的信息安全保障体系。</p>
				<p class="font13 black line-height24">
					（2）我们从数据的生命周期角度出发，在数据收集、存储、显示、处理、使用、销毁等各个环节建立了安全防护措施，根据信息敏感程度的级别采取不同的控制措施，包括但不限于访问控制、SSL加密传输、AES256bit或以上强度的加密算法进行加密存储、敏感信息脱敏显示等。
				</p>
				<p class="font13 black line-height24">
					（3）我们对可能接触到您信息的员工也采取了严格管理，可监控他们的操作情况，对于数据访问、内外部传输使用、脱敏、解密等重要操作建立了审批机制，并与上述员工签署保密协议等。与此同时，我们还定期对员工进行信息安全培训，要求员工在日常工作中形成良好操作习惯，提升数据保护意识。
				</p>
				<p class="font13 black line-height24">
					（4）尽管有前述的安全措施，但同时也请您理解在网络上不存在“完善的安全措施”。我们会按现有的技术提供相应的安全措施来保护您的信息，提供合理的安全保障，我们将尽力做到使您的信息不被泄露、损毁或丢失。
				</p>
				<p class="font13 black line-height24">
					（5）您的帐户均有安全保护功能，请妥善保管您的帐号及密码信息，切勿将密码告知他人，如果您发现自己的个人信息泄露，特别是您的帐号和密码发生泄露，请您立即与我们的客服联系，以便我们采取相应的措施。</p>
				<p class="font13 black line-height24">
					（6）请您及时保存或备份您的文字、图片等其他信息，您需理解并接受，您接入我们的服务所用的系统和通讯网络，有可能因我们可控范围外的因素而出现问题。</p>
				<h5 class="font15 black marginTop30">9、信息安全事件处置</h5>
				<p class="font13 black line-height24">
					在不幸发生个人信息安全事件后，我们将按照法律法规的要求向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。事件相关情况我们将以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，上报个人信息安全事件的处置情况。
				</p>
				<h5 class="font15 black marginTop30">10、未成年人信息保护</h5>
				<p class="font13 black line-height24">
					{{configData.corpName}}非常重视对未成年人个人信息的保护。若您是18周岁以下的未成年人，在使用{{configData.corpName}}的服务前，应事先取得您家长或法定监护人的书面同意。
				</p>
				<h5 class="font15 black marginTop30">11、用户个人信息管理</h5>
				<p class="font13 black line-height24">
					（1）您可以随时登录“我的{{configData.corpName}}”-个人中心、常用信息等，查询、更正及删除自己的账户信息，修改个人资料、隐私设置，修改收货地址等使用{{configData.corpName}}服务时您提供的个人信息。
				</p>
				<p class="font13 black line-height24">
					（2）您可通过如下路径注销{{configData.corpName}}账户：拨打{{configData.corpName}}官网公布的客服电话(如国内：010-6458-1922)，根据语音提示转至客服人工服务，由客户验证后完成账号的注销。注销某{{configData.corpName}}账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或共享与该账户相关的个人信息，但之前的信息我们仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。
				</p>
				<h5 class="font15 black marginTop30">12、从中国大陆地区以外访问我们的网站</h5>
				<p class="font13 black line-height24">
					如果您从中国大陆以外地区访问我们网站，请您注意，您的信息可能被传送至、存储于中国大陆，并且在中国大陆进行处理。中国大陆的数据保护法和其他法律可能与您所在国家/地区的法律不同，但请相信我们将采取措施保护您的信息。选择使用我们的服务的同时，您了解并同意您的信息可能被传送至我们的网站，并如本隐私政策所诉的与我们共享信息的第三方。
				</p>
				<h5 class="font15 black marginTop30">13、隐私政策的适用范围</h5>
				<p class="font13 black line-height24">
					除某些特定服务外，我们所有的服务均适用《{{configData.corpName}}隐私政策》。特定服务将适用特定的隐私政策。该特定服务的隐私政策构成《{{configData.corpName}}隐私政策》的一部分。如任何特定服务的隐私政策与《{{configData.corpName}}隐私政策》有不一致之处，则适用特定服务的隐私政策。
				</p>
				<p class="font13 black line-height24">
					请您注意，《{{configData.corpName}}隐私政策》不适用于以下情况：通过我们的服务而接入的第三方服务（包括任何第三方网站）收集的信息；通过在我们服务中进行广告服务的其他公司和机构所收集的信息。
				</p>
				<h5 class="font15 black marginTop30">14、隐私政策的修改</h5>
				<p class="font13 black line-height24">
					{{configData.corpName}}会在必要时修改隐私政策，请您理解，我们可能会适时修订本隐私政策，我们将标注本隐私政策最近更新的日期，更新将于发布时生效。未经您明确同意，{{configData.corpName}}不会削减您按照本隐私权政策所应享有的权利。对于重大变更，{{configData.corpName}}还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明隐私权政策的具体变更内容）。请您经常回访本隐私政策，以阅读最新版本。
				</p>
				<h5 class="font15 black marginTop30">15、法律</h5>
				<p class="font13 black line-height24">
					隐私政策要与该国的国家法律解析一致，包括法律条款中有争议抵触的内容。用户和上海{{configData.corpName}}商务有限公司一致同意服从法院的管辖。若有任何条款与法律相抵触，那这些条款将按尽可能接近的方法重新解析，而其它条款则保持对用户产生法律效力和影响，因解释本隐私政策以及用户通过{{configData.corpName}}预订任何产品而导致的争议，将同意接受北京市朝阳区人民法院的管辖。
				</p>
				<p class="font13 black line-height24">更新日期：2021-10-25</p>
			</div>
			<!-- 火车票服务协议 -->
			<div class="content" v-if="params == 3">
				<p class="font13 black line-height24">《火车票信息服务协议》（以下简称“本协议”）是{{configData.corpFullName}}（以下简称 “我司”）与平台用户
					(以下简称“您”)就火车票购票、12306账号授权托管使用等相关事宜所订立的合约。您通过网络页面点击“确认”或“同意”或开始使用本公司提供的服务，即表示您同意接受本协议的全部约定内容以及与本协议有关的已经发布或将来可能发布的各项规则、页面展示、操作流程、公告或通知（以下统称“规则
					”）。</p>
				<p class="font13 black line-height24">
					在接受本协议之前，请您仔细阅读本协议的全部内容（特别是以粗体下划线标注的内容）。如果您不同意本协议的任意内容，或者无法准确理解该条款的含义，请不要进行任何操作或拒绝使用本公司提供的火车票购票服务。
				</p>
				<h5 class="font13 black marginTop30">第一条 服务描述</h5>
				<p class="font13 black line-height24">
					1、我司通过铁路官方网站为您提供火车票信息服务，或由您自行登录已有12306账号进行查询、购票、退票、改签等操作，如您未注册12306账号，您同意我司先使用您的姓名、身份证号、手机号等信息为您提供12306账号代为注册的便捷服务。
				</p>
				<p class="font13 black line-height24">
					2、全国各铁路局对火车票售票有不同的规定，我司不对服务成功率做100%承诺。如预定失败，订单金额将退回您的支付账户，到账日期一般为3-7个工作日。</p>
				<p class="font13 black line-height24">
					3、通过我司平台，您可以自由选择包括但不限于保险、景点代金劵、酒店代金劵、用车劵套餐服务等附加服务，但我司承诺不会强制您购买任何产品或服务。</p>
				<h5 class="font13 black marginTop30">第二条 购票说明</h5>
				<p class="font13 black line-height24">1、我司接受二代身份证及护照、台胞证、港澳通行证预订，其他证件暂不支持。</p>
				<p class="font13 black line-height24">2、一张有效身份证件同一乘车日期同一车次限购一张车票。（儿童用成人的证件号情况除外）</p>
				<p class="font13 black line-height24">3、同一笔订单可购买的车票数量不超过5张，如购票乘车人数超出5位，请分开预订。</p>
				<h5 class="font13 black marginTop30">第三条 取票说明</h5>
				<p class="font13 black line-height24">
					1、使用二代身份证预订的客户，可持预订时所使用的乘车人有效二代身份证到车站售票窗口、铁路客票代售点或车站自动售票机上办理取票手续，部分高铁站可持二代居民身份证直接检票进站，以各铁路站点实际情况为准。
				</p>
				<p class="font13 black line-height24">
					2、如预订时使用的乘车人二代身份证无法识别或使用护照预订的客户，请持预订时留下的有效证件原件及本公司给您发送的火车票订单号至火车站售票点，由售票员核实后办理换票手续。</p>
				<p class="font13 black line-height24">3、若您在预订成功后、换票前，不慎遗失有效身份证件，须由您本人到乘车站铁路公安制证口办理临时身份证明。</p>
				<p class="font13 black line-height24">
					4、纸质火车票作为唯一的报销凭证，如您需要报销，请提前至火车站换取纸质车票并妥善保管。如您未提前换票或纸质票遗失，我司概不提供火车票票款发票。</p>
				<h5 class="font13 black marginTop30">第四条 退票及改签说明</h5>
				<p class="font13 black line-height24">1、在线申请退票退款须同时满足以下条件：</p>
				<p class="font13 black line-height24">①发车前；②未取纸质车票(若已取，需至车站窗口办理退票业务)</p>
				<p class="font13 black line-height24">2、根据铁路客运规定，退票手续费规则如下：</p>
				<p class="font13 black line-height24">开车前8天及以上退票的，不收取退票手续费；</p>
				<p class="font13 black line-height24">开车前48小时以上不足8天的，手续费为5%票价；</p>
				<p class="font13 black line-height24">开车前24-48小时的，手续费为10%票价；</p>
				<p class="font13 black line-height24">开车前24小时以内，手续费为20%票价。</p>
				<p class="font13 black line-height24">退票手续费按最低按2元计收。</p>
				<p class="font13 black line-height24">最终退票手续费以铁路客运机构实际上收取为准。</p>
				<p class="font13 black line-height24">3、支持在线改签（热门线路除外），一张车票只能改签一次，且须同时满足以下条件：</p>
				<p class="font13 black line-height24">①服务时间：5:00-23:30；②未取纸质车票；③1月28日起可支持开车后改签，办理开车后改签的车票不能退票。</p>
				<p class="font13 black line-height24">4、在铁路客运机构有运输能力的前提下，改签规则如下：</p>
				<p class="font13 black line-height24">①可改签车次：</p>
				<p class="font13 black line-height24">
					开车前48小时(不含)以上，可改签、变更到站预售期内的其他列车；开车前48小时以内，可改签开车前及开车后至票面日期当日24:00之间的其他列车；</p>
				<p class="font13 black line-height24">开车后，旅客仍可改签票面日期24:00前的其他列车。</p>
				<p class="font13 black line-height24">改签、变更到站均只能办理一次。开车前48小时-8天内的，改签或变更到站至距开车8天以上的其他列车退票的，仍核收5%的退票手续费。
				</p>
				<p class="font13 black line-height24">②改签手续费：</p>
				<p class="font13 black line-height24">
					改签及变更到站后新车票票价高于原车票的，需补收票价差额。改签、变更到站后新票票价低于原票的，退还差额，对差额部分核收退票费并执行现行退票费标准，应退票款在规定时间内退回购票时所使用的在线支付工具。改签、变更到站后的新票票价等于原票的，无需办理支付。
				</p>
				<p class="font13 black line-height24">
					5、如发生网络技术故障等情形，造成我司临时性暂停在线退票、改签服务，您可以在发车前携带纸质车票及购票时使用的有效身份证件至火车站的退票窗口办理。</p>
				<h5 class="font13 black marginTop30">第五条 免责申明</h5>
				<p class="font13 black line-height24">
					1、因全国各铁路局会随时调整车次、票价、坐席等信息，或者网络传输误差，故我司显示产品信息（包括但不限于车次、票价、坐席）可能不是最新或者存在误差，供您做一般参考，最终以实际购买的产品为准。</p>
				<p class="font13 black line-height24">2、因全国各铁路局会随时调整车次，或因自然灾害等不可抗力因素导致的火车停运等问题，本公司不承担责任。</p>
				<p class="font13 black line-height24">
					3、如因您提供错误的订单信息（姓名、证件号码、日期、车次、座位类型等）或者因您自身原因导致无法取票、车票丢失、车票损毁等情况所导致的损失，您需自行承担相关损失后果。</p>
				<p class="font13 black line-height24">4、我司将按承诺时限向客户订单支付的原渠道发起退款，退款到帐时间由支付渠道和银行决定。</p>
				<p class="font13 black line-height24">
					5、我司提供的是火车票信息服务，您接受本协议，意味着您同意我司使用您填写的乘客信息或12306账号进行相关操作，包括但不限于授权使用您的乘客信息为您或他人执行查询、购票、退票、改签、注册等操作，同时您必须遵守12306购票规定的服务条款(https://kyfw.12306.cn/otn/regist/rule)。
				</p>
				<p class="font13 black line-height24">
					6、如果您提供自有的12306账号用于购票，表示您同意将您的12306账号和密码授权给本平台托管，以方便为您或他人执行查询、购票、退票、改签等操作，平台也将严格保护您的隐私信息。如发生账号及密码信息变更，请及时在本平台更新，本平台不对因此带来的查询、购票、退票、改签等操作失败问题承担责任。
				</p>
				<h5 class="font13 black marginTop30">第六条 解决争议适用法律法规约定</h5>
				<p class="font13 black line-height24">
					如果您在本协议履行过程中，您对相关事宜发生争议，您同意按照中华人民共和国颁布的相关法律法规来解决争议，并同意接受本协议签订地苏州市工业园区人民法院的管辖。</p>
				<h5 class="font13 black marginTop30">第七条 其他</h5>
				<p class="font13 black line-height24">1、在使用我司提供的服务前务必认真阅读本协议，一旦您使用本公司提供的服务即表示您同意与我司签订本协议且同意受本协议之约束。</p>
				<p class="font13 black line-height24">
					2、您同意并授权我司有权随时对本协议内容进行单方面的变更，并以在本公司及关联公司网站、APP、微信等第三方渠道公告的方式提前予以公布，无需另行单独通知您；若您在本协议内容公告变更生效后继续使用我司服务的，表示您已充分阅读、理解并接受变更修改后的协议内容，也将遵循变更修改后的协议内容使用相关服务；若您不同意变更修改后的协议内容，您应在变更生效前停止使用本公司提供的火车票购票服务及其他相关服务。
				</p>
				<p class="font13 black line-height24">
					3、本协议未尽事宜，以我公司后续公布的相关规则为准，按照我公司公布的相关规则亦未对所涉事项进行规定的，以铁路部门公布的规定、政策为准。</p>
				<p class="font13 black line-height24">
					4、本协议于铁路部门现有相关规定、政策的基础上进行制定，您知晓并同意您有义务知悉铁路部门公布的相关规定、政策，如后续因铁路部门相关规定、政策变动导致本协议相关条款与实际不符的，您与我司双方均同意以实际的规定、政策为准，同时如发生前述情形，您同意就给您造成的损失不予追究我司责任。
				</p>
			</div>
			<!--账户授权协议-->
			<div class="content" v-if="params == 4">
				<p class="font13 black line-height24">《火车票信息服务授权协议》（以下简称“本协议”）是{{configData.corpFullName}}（以下简称
					“我公司”）与平台用户 (包括账户所有人、代订人、出行人，以下简称“您”)就火车票信息服务、12306账号注册及授权托管、使用等相关事宜所订立的合约。<span
						class="font-bold">请您务必在使用我公司的产品或接受我公司的服务前仔细阅读本协议，特别是加粗字体显示的内容。如您不同意本协议的任何内容，或者无法准确理解协议条款的含义，请您不要进行下一步操作并拒绝使用我公司的产品或服务。如您在网络页面点击“确认”或“同意”或“注册”或开始使用我公司的产品或服务，则表示您已理解并接受本协议的全部条款以及与本协议有关的各项规则、说明、页面展示、操作流程、公告或通知（以下统称“规则”）。
					</span></p>
				<h5 class="font13 black marginTop30 font-bold">第一条 服务描述</h5>
				<p class="font13 black line-height24">
					1.1您可以通过我司平台自行登录您的12306账号进行查询、订票、退票、改签、候补等操作。如您未注册12306账号，但需要继续使用我公司服务的，您需要授权我公司代您申请注册12306账号。</p>
				<p class="font13 black line-height24">1.2如您就我公司的产品或服务有任何疑问，您可通过我公司在线客服进行咨询，我公司将竭诚为您解释和说明。 </p>
				<h5 class="font13 black marginTop30 font-bold">第二条 授权与许可 </h5>
				<p class="font13 black line-height24 font-bold">
					2.1如您未注册12306账号而继续使用我公司的服务或通过我公司平台进一步使用12306网站的服务的，您同意并授权我公司及第三方服务商收集、使用您的身份信息（包括姓名、身份证号、手机号等）为您提供代为注册12306账号。
				</p>
				<p class="font13 black line-height24 font-bold">
					2.2部分场景需要您进行人证核验。人证核验时，您同意我公司收集您的人脸照片、身份证件照片等身份信息（具体收集范围和使用场景详见认证时的相关说明、提示和协议），并同意我公司将收集的信息提供给12306以及法律法规允许或政府机关授权的机构进行核验。
				</p>
				<p class="font13 black line-height24 font-bold">
					2.3如您提供自有的12306账号用于购票，表示您同意将您的12306账号和密码授权给本平台托管和使用，且您承诺您有作出该等处分的权利。如发生账号及密码信息变更，请您及时在平台更新，如更新不及时导致查询、订票、退票、改签、候补、会员权益处分等操作失败的，我公司不承担任何责任。
				</p>
				<p class="font13 black line-height24 font-bold">
					2.4您同意并授权我公司收集、使用您以及您添加乘客的身份信息、行程信息、手机号码为您或他人执行查询、订票、退票、改签、注册、候补、会员权益处分等操作。</p>
				<p class="font13 black line-height24 font-bold">
					2.5您同意并授权我公司收集您的设备信息。为了降低您预订火车票失败的几率，您在使用火车票的相关功能（如预订、退票、改签）时可以选择开启您设备的蓝牙、WIFI、设备状态、您的地理位置（位置信息）、相机（摄像头）、相册（图片库）以及麦克风的访问权限，并授权我公司获取您的蓝牙名、手机号、IP、MAC地址、IMEI、VoiceMailNumber
					、IMSI、SIM卡序列号、屏幕信息、系统属性、传感器信息、WIFI信息、APP列表、网络信息中的一个或多个并提交给12306，以避免因为缺乏上述信息导致使用12306火车票功能时可能出现的失败。
				</p>
				<p class="font13 black line-height24"><span
						class="font-bold">2.6您通过我公司平台接受第三方的服务的，您授权我公司将您的用户信息传递给第三方，您承诺您提供的乘客信息均已事先获得了当事人的充分授权，且当事人已明知我公司将使用其信息为您或当事人或其他第三人提供查询、订票、退票、改签、候补、会员权益处分等操作服务，而不持异议。</span>我公司将通过加密方式传递您的信息并要求第三方严格遵守相关法律法规与监管要求，依法使用您的信息，并保证您的信息安全。
				</p>
			</div>
			<!-- 儿童购票说明 -->
			<div class="content" v-if="params == 5">
				<p class="font13 black line-height24">1．儿童不能单独乘车。为确保儿童出行安全，儿童需有成人同行。</p>
				<p class="font13 black line-height24">
					2．身高在1．2米～1．5米的儿童需购买儿童票身高超过1．5米的，需购买成人票。每位成年旅客可以免费携带一名身高1．2米以下的儿童；超过一名时，其他儿重需购买儿童票。</p>
				<p class="font13 black line-height24">3．由于儿童票价格不定，先按照成人票价收取费用，之后会根据实际出票价格退换差价。</p>
				<p class="font13 black line-height24">4．卧铺儿童票与同行成人各有床位，需要与儿童共用床位的，请至车站买票。</p>
				<p class="font13 black line-height24">5．儿重使用同行成人的证件购票，取票时请用成人证件在自助取票机或取票商口取票。</p>
				<p class="font13 black line-height24">
					6．请根据儿童实际身高来购票，如在进站时由于儿童身高超过标准而无法进站，{{$appConfig.corpName}}不承担任何责任。</p>
			</div>
			<!-- 姓名填写规则	 -->
			<div class="content" v-if="params == 6">
				<p class="font13 black line-height24">1．如姓名中包含生僻字可直接输入拼音代替，例：“李小垚”可输入为“李小yao＂。</p>
				<p class="font13 black line-height24">2．如姓名中有繁体字无法输入时，可用简体替代。</p>
				<p class="font13 black line-height24">
					3．姓名中汉字与英文字符合计超过30个（1个汉字算2个字符）的，需按姓名中第一个汉字或英文字符开始顺序连续输入30个字符（空格字符不输入），其中英文字符输入时不区别大小写</p>
				<p class="font13 black line-height24">4．姓名中包含＂或“时，请仔细辨析身份证原件上的“＂＇或”准确输入。</p>
				<p class="font13 black line-height24">5．注:当该乘客是【员工】时,人员姓名不可修改;当预订是【常旅客】时,人员姓名可以修改。</p>
			</div>
			<!--预订须知-->
			<div class="content" v-if="params == 7">
				<div v-for="(item, index) in ydxzContent" :key="index">
					<h5 class="font15 black marginTop30">{{item.cName}}</h5>
					<div v-for="(str,key) in item" :key="key">
						<p class="font13 black line-height24" v-if='key.indexOf("by")!=-1'>{{str}}</p>
					</div>
				</div>
			</div>

			<!--机票预订须知-->

			<div class="content" v-if="params == 8">
				<h4>关于民航乘客携带锂电池以及危险物品乘机的限制公告</h4>
				<h5 class="font15 black marginTop30">一、锂电池携带规定</h5>
				<p class="font13 black line-height24">旅客为个人自用内含锂电池的便携式电子装置 (照相机、手机、手提电脑、便携式摄像
					机等)可作为手提行李携带登机，并且锂电池的额定能量值不得超过100Wh（瓦特小时）。超过100Wh但不超过160Wh的，经航空公司批准后可以装在交运行李或手提行李中的设备上。超过160Wh的锂电池严禁携带。
				</p>
				<p class="font13 black line-height24">便携式电子装置的备用电池必须单个做好保护以防短路（放入原零售包装或以其他
					方式将电极绝缘，如在暴露的电极上贴胶带，或将每个电池放入单独的塑料袋或保护盒 当中），并且仅能在手提行李中携带。经航空公司批准的100 -160Wh的备用锂电池只能携带两个。
					旅客携带锂电池驱动的轮椅或其他类似的代步工具和旅客为医疗用途携带的、内含锂电池的便携式医疗电子装置的，必须依照相关规定和要求携带并经航空公司批准。旅客可提前与航空公司咨询以便获得帮助。</p>
				<p class="font13 black line-height24">按照中国民航局的规定，携带乘机的锂电池必须符合相关的安全要求，建议旅客从正规渠道购买正规厂家生产的锂电池，以免给自己或他人造成伤害。
				</p>
				<p class="font13 black line-height24">"充电宝"携带规定</p>
				<p class="font13 black line-height24">《关于民航旅客携带"充电宝"乘机规定的公告》</p>
				<p class="font13 black line-height24">充电宝是指主要功能用于给手机等电子设备提供外部电源的锂电池移动电源。根据现行有效国际民航组织《危险物品安全航空运输技术细则》和
					《中国民用航空危险品运输管理规定》，旅客携带充电宝乘机应遵守以下规定：</p>
				<p class="font13 black line-height24">充电宝必须是旅客个人自用携带。</p>
				<p class="font13 black line-height24">充电宝只能在手提行李中携带或随身携带，严禁在托运行李中携带。</p>
				<p class="font13 black line-height24">
					充电宝额定能量不超过100Wh,无需航空公司批准；额定能量超过100Wh但不超过160Wh，经航空公司批准后方可携带，但每名旅客不得携带超过两个充电宝。</p>
				<p class="font13 black line-height24">严禁携带额定能量超过160Wh的充电宝；严禁携带未标明额定能量同时也未能通过标注的其他参数计算得出额定能量的充电宝。</p>
				<p class="font13 black line-height24">不得在飞行过程中使用充电宝给电子设备充电。对于有启动开关的充电宝，在飞行过程中应始终关闭充电宝。</p>

				<p class="font13 black line-height24">锂电池、充电宝额定能量的判定方法</p>
				<p class="font13 black line-height24">若锂电池、充电宝上没有直接标注额定能量Wh（瓦特小时），则锂电池、充电宝额定能量可按照以下方式进行换算:</p>
				<p class="font13 black line-height24">如果已知锂电池、充电宝的标称电压(V )和标称容量(Ah)，可以通过计算得到额定能量的数值：</p>
				<p class="font13 black line-height24">Wh= V x Ah</p>
				<p class="font13 black line-height24">标称电压和标称容量通常标记在锂电池、充电宝上。</p>

				<p class="font13 black line-height24">如果锂电池、充电宝上只标记有毫安时(mAh)，可将该数值除以1000得到安培小时(Ah)。</p>
				<p class="font13 black line-height24">例如：锂电池、充电宝标称电压为3.7V，标称容量为760 mAh ，其额定能量为：</p>
				<p class="font13 black line-height24">760 mAh ÷ 1000 = 0.76Ah</p>
				<p class="font13 black line-height24">3.7V×0.76Ah=2.9Wh</p>

				<h5 class="font15 black marginTop30">二、不能随身携带，但可托运的物品</h5>
				<p class="font13 black line-height24">
					除管制刀具以外的如水果刀、剃须刀等生活用刀、手术刀等专业刀具、化妆品，如来不及托运，可以办理相关手续后，在规定时间内由安检部门代为保管。（列图）</p>

				<h5 class="font15 black marginTop30">三、严禁携带及托运的物品</h5>
				<p class="font13 black line-height24">您乘坐飞机时禁止：
					枪支(含各种仿真玩具枪、微型发射器及各种类型的攻击性武器)、弹药、军械、警械、爆炸物品、易燃易爆物品、剧毒物品、放射性物品、腐蚀性物品、危险溶液及其他禁运物品带上飞机或夹在行李、货物中托运。凡携带或夹带上述物品的一经查出，立即移交公安机关处理。
				</p>

				<h5 class="font15 black marginTop30">四、限制随身携带的液态物品的种类</h5>
				<p class="font13 black line-height24">液体饮品：如矿泉水、茶水、碳酸饮料、牛奶、酸奶、果汁等；</p>
				<p class="font13 black line-height24">洗漱化妆用品：如牙膏、洗发水、沐浴露、润肤露、剃须泡沫；</p>
				<p class="font13 black line-height24">凝胶用品：定型水、啫喱水等；</p>
				<p class="font13 black line-height24">液态食品：甜面酱、瓶装罐装罐头等食品；</p>
				<p class="font13 black line-height24">气雾剂：喷雾液</p>
				<p class="font13 black line-height24">不能通过安全检查的液态物品的处置办法</p>
				<p class="font13 black line-height24">药品：如眼药水、口服或外用药液、喷剂等；</p>
				<p class="font13 black line-height24">如果您携带了不符合安全要求的液态或凝胶物品，这些物品及容器只能弃置。安检部门
					不设立保管或储存服务。请将液体、凝胶及喷雾类物品放在托运行李内，以便顺利通过安全检查。</p>

			</div>

			<!--账户授权协议-->
			<div class="content" v-if="params == 9">
				<p class="font13 black line-height24">关于{{configData.corpName}}</p>
				<p class="font13 black line-height24">
					{{configData.corpFullName}}专注于商旅十余年，针对商旅客户高标准要求，配备了高水准、专业的团队，为您24小时提供无微不至的服务，满足客户随时随地地快速预定机票、全球酒店、航空保险、全球用车、旅游签证等一站式服务。同时公司整合全球机场资源优势，让{{configData.corpName}}客户出行享受到更全面贴心的服务。
				</p>
			</div>
			<div class="text-center font12 black" >
				@Copyright  {{configData.corpFullName}} 版权所有	
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="close">关 闭</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		name: "TermAgreement",
		props: {
			visible: {
				type: Boolean,
				default: false,
			},
			// 公共， 显示内容参数
			params: {
				type: Number,
				default: 1,
			}
		},
		data() {
			return {
				showType: {
					1: '服务协议和隐私条款',
					2: '隐私条款',
					3: '火车票服务协议',
					4: '账号授权协议',
					5: '儿童购票说明',
					6: '姓名填写规则',
					7: '预订须知',
					8: '机票预订须知',
					9: '公司简介',
					10: '服务协议',
				},
				configData:{}, //总公司信息
				ydxzContent:[],
			}
		},
		mounted(){
			this.configData = this.$common.getConfigData();
			if(this.params == 7){
				this.ydxzContent = [];
				this.getYdxzContent();
			}
		},
		methods: {
			close() {
				this.$emit('cancelService', false)
			},
			//获取预订须知的内容
			getYdxzContent(){
				var queryString = {parNo:'150'};
			  		this.$conn.getConn("commu.getBClass")(queryString,(resp)=>{
			  			this.ydxzContent = resp.data.list;
			  		},()=>{
			
			  	})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.sd-main {
		/deep/ .el-dialog {
			width: 600px;
			height: 640px;
			overflow: hidden;
		}

		/deep/ .el-dialog__body {
			padding: 10px 20px;
		}

		.content {
			width: 100%;
			height: 500px;
			overflow-y: auto;
			text-align: left;
		}

		.font13 {
			font-size: 13px;
		}

		.font15 {
			font-size: 15px;
		}

		.black {
			color: black;
		}

		.line-height24 {
			line-height: 24px;
		}

		.marginTop30 {
			margin-top: 30px;
		}

	}
</style>
