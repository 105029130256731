const state = {
	// 火车票查询页
	trainQuery: {
		//火车票列表查询参数
		searchData: {
			depDate: '', //出发日期
			tripType: 1, // 1-因公 2-因私 null-为了解决triptype控件传值为数字类型的问题， 默认因公在火车票查询页面处理

			depCity: '', //出发城市
			arrCity: '', //到达城市
			depCitySzm: '', //出发城市三字码
			arrCitySzm: '', //到达城市三字码
			depCityId: '', // 出发城市ID
			arrCityId: '', // 到达城市ID

			depInputName: '', // 出发城市加三字码
			arrInputName: '', // 到达城市加三字码

			depCityIdList: [], // 出差申请单行程出发城市Id
			arrCityIdList: [], // 出差申请单行程到达城市Id


			depStationInfo: {}, //到达站点信息
			arrStationInfo: {}, //出发站点信息
			ccsqdReset: false, // 重置选择的出差申请单


			ccsqdId: '', //出差申请单id
			ccsqdNo: '', //出差申请单号
			routeId: '', //行程id
			cxrList: [], //出行人列表
			detail: {}, //出差申请单信息
			currentRoute: {}, //多选行程中的其中一项

			bookStyle: null, //  默认单订企业  1. 单订  2. 出差申请单

			zcGnjp: '', //是否开启差旅政策
			outerSize: '', //是否可为外来人订票
			cabinScreen: '', //舱位
			wlcor: '', //是否勾选外来人
			zhDate1: '', //中文日期
			isSeekGaoAndDong: 0, //1||0
			fromWhere: 'trainQuery',


			fyys: 0, //费用
			cabin: '', // 坐席

			empRank: '', // 本次最低最低职级
			useNameclbz: '', // 本次使用谁的名字
			clzcList: [], // 本次的差旅政策列表

			clzcCode: '',
			clzcItem: '', // 差旅政策

			trainDateRange: '14', // 日期参数

			minDate: '', // 日期最小值
			maxDate: '', // 日期最大值

			gkfsRole: 0, // 10999    出差申请单模式下   是否按出差申请单进行管控   默认  0不管控 1： 管控  2安差标管控
			changeRouteRole: 0, // 10991    查询出差申请单可更改行程
			changeDateRole: 0, // 10992    查询出差申请单可变更日期
			changeDateRange: 0, // 10993    查询出差申请单日期范围  默认 0天     前后几天

			ccsqCanKaoDate: '',
			search: false, // 控制火车票搜索

			violateCode: '', //违背事项代码
			violateItem: '', //违背事项说明
			reasonCode: '', //违背原因代码
			reasonDesc: '', //违背原因说明

			maxDay: 15, // 火车票预定最大天数
			changeTripType: 1, // 火车票改签单 用户预订时是因公还是因私预订

			trQueryStatus: 0, // 火车票加载状态  0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常

			/*
			 *  整体加载条件
			 * 		城市与日期同时变化 行程变化 比较 行程id 出差申请单id	
			 *  		重选出差申请单，
			 * 
			 * 局部加载条件
			 * 		城市 单独变化
			 * 		日期单独变化
			 */
			allLoading: true, // 整体加载
			partLoading: false, // 局部加载			   
			searchNum: 0, //搜索次数 初次所搜与切换出差申请单 重置为0， 再次搜索 不改变其他条件的情况下 为局部搜索

			reFresh: false, //搜索完成后刷新 筛选
		},
	},
	// 火车票数据查询
	trainData: {
		trainList: [], // 火车票列表
		feeList: [], // 火车票服务费列表
		ifBook: true, // 是否可预订
		notBookMsg:"", //不可预订提示
	},
	cxrList: [], // 出行人列表
	lsCxrList:[], //火车票临时出行人列表 
	passengerLength: 1, // 乘车人列表长度，当因私模式 与单订模式时使用

	// 选中出发火车票信息
	trainYdInfo: {},


	// 火车票预订数据
	trainEdit: {
		project: {
			id: '', // 项目id
			projectCode: "", // 项目名称
			projectName: '' // 项目代码
		},
		spgz: {
			gzId: '', // 审批规则id
			gzmc: '', // 审批规则名称
			gzdm: '' // 审批规则代码
		},
		linker: {
			linkEmail: '', // (string, optional): 联系邮箱 ,
			linkMan: '', // (string, optional): 联系人 ,
			linkPhone: '', // (string, optional): 联系电话 ,
		},
		isShowChooseZuo: false, // 是否选座
		bxprice: 0, //保险金额
		bxOrderList: [], // 保险订单列表
		seatInfo:{
			chooseSeatList: [], // 选中座位列表，
			seatIdList: [], // 座位id列表
		}
		
	}
};
export default state
