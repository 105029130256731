<template>
  <div class="yj-pagination" :style="paStyle">
    <el-pagination
      :small="small"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageSizes"
      :page-size="pageSize"
      :layout="layout"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
    paStyle: {
      type: String,
      default: "",
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    pageSize: {
      type: [Number, String],
      default: 10,
    },
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    layout: {
      type: String,
      default(){
        let str = "total, sizes, prev, pager, next, jumper"
        if(this.small){
          str = "prev, pager, next"
        }
        return str
      },
    },
    pageSizes: {
      type: Array,
      default() {
        return [10, 20, 30, 100];
      },
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      this.$emit("handleCurrentChange", val);
    },
  },
};
</script>

<style scoped lang="scss">
</style>
