import common from '@/plugins/common.js';
import tkMap from './tkMap.js';
const mutations = {

	//  设置机票出差申请单
	setTkCCsqd(state,obj){
		state.chooseCcsqd= obj;
	},
	// 设置机票查询参数
	setTkQuery(state, obj) {
		for(var key in obj) {
			state.tkQuery[key] = obj[key];
		}
	},
	// 去程
	// 存储处理为航班列表数组的航班列表
	setFlightList(state,obj){
		state.flightList= obj;
	},
	// 存储航班查询数据
	setFlightMap(state,obj){
		for(var key in obj){
			state.flightMap[key] =  obj[key]
		}
	},
	
	// 机票筛选条件存储
	setTkFilterMap(state,obj){
		for(var key in obj){
			state.tkFilterMap[key] =  obj[key]
		}
	},
	// 存储选择一个航班
	setChooseFlight(state,obj){
		state.chooseFlight =  obj
	},
	// 返程  4个方法
	// 存储处理为航班列表数组的航班列表
	setFlightListBack(state,obj){
		state.flightListBack= obj;
	},
	// 存储航班查询数据
	setFlightMapBack(state,obj){
		for(var key in obj){
			state.flightMapBack[key] =  obj[key]
		}
	},
	
	// 机票筛选条件存储
	setTkFilterMapBack(state,obj){
		for(var key in obj){
			state.tkFilterMapBack[key] =  obj[key]
		}
	},
	// 存储选择一个航班
	setChooseFlightBack(state,obj){
		state.chooseFlightBack =  obj
	},
	




	// 乘机人对象
	setCxrList(state,obj){
		state.cxrList =  obj
	},
	// 机票临时 乘机人对象（处理出行人因公因私切换时，因私会清空出行人）
	setLsCxrList(state,obj){
		state.ysCxrList =  obj
	},
	// 机票填写页对象设置
	setTkEdit(state,obj){
		for(var key in obj){
			state.tkEdit[key] =  obj[key]
		}
	},
	// 南航一键入会
	setTkNhrh(state,obj){
		state.nhrh = obj;
	},
	
	// 清空机票相关信息   tkMap 来自于引入数据
	initTkInfo(state,obj){
		let tkMapCopy = common.deepCopy(tkMap)
		for(var key in tkMapCopy){
			state[key] = tkMapCopy[key]
		}
	}
	
	
	
	
}
export default mutations