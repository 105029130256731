<template>
	<el-button type="primary" :style="copyRightClass" >
	<!-- 	<div style="width:300px;margin:0 auto; padding:20px 0;">
		    <a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302005323" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
				<img :src="wjIcpLogo" style="float:left;"/>
				<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">{{compInfo.wjIcp}}</p></a>
		</div> -->
		<div calss="c-main" :style="font">
			<a v-show="compInfo.wjIcp" target="_blank" :href="'http://www.beian.gov.cn/portal/registerSystemInfo?recordcode='+compInfo.recordcode" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;">
			 		<img :src="wjIcpLogo" style="float:left;"/>
			 		<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;" :style="a">{{compInfo.wjIcp}}</p>
			</a>
			 
			<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;" :style="a" class="c-a">
				
				<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;" :style="a">{{compInfo.etCopyrightInformation}}</p>
			
			</a>
		</div>
	</el-button>	
</template>

<script>
	import wjIcpLogo from '@/assets/image/home/guoHui.png'
	export default{
		name: 'CorpRight',
		props:{
			compInfo:{
				type:Object,
				default(){
					return {};
				}
			},
			copyRightClass:{ // 底部主题样式
				type:Object,
				default() {
					return {
						'width': 100+'%',
						'min-height': 56+'px',
						'cursor':'initial',
						'background':'#1C83ED',
					}
				}
			},
			a:{
				type:Object,
				default() {
					return {
						color: '#FFFFFF',
						'padding-left':10+'px',
						'text-decoration':'none',
					}
				}
			},
			font:{ // 设置文字
				type:Object,
				default() {
					return {
						'font-size': 12+'px',
						'font-family': '微软雅黑',
						'font-weight': 400,
						 color: '#FFFFFF',
						'line-height': 28+'px',
						padding:14+'px',
						opacity: 0.7,
						width: 1200+'px',
						margin: 0+' '+'auto',
						'text-align': 'center',
					}
				}
			},
		},
		data() {
			return {
				wjIcpLogo:wjIcpLogo
			}
		},
		computed:{
			// 公司信息
			configData(){
				let configData = this.$common.getConfigData();
				return configData
			}
		},
		methods: {
			goToIcp(){
				
				this.$router.push({path:'/https://beian.miit.gov.cn/#/Integrated/index'})
			},
		}
	}
</script>

<style lang="scss" scoped>
	.el-button{
		border-radius: 0;
		border:none;
	}
	.c-main{
		.c-a:hover{
			opacity: 0.7;
		}
	}
		
</style>
