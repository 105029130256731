import Vue from "vue";
import Vuex from "vuex";
import state from './state';
import mutations from './mutations';
import actions from './actions';

// vuex 数据持久化
import createPersistedState from "vuex-persistedstate";

import tk from './tk';
import train from './train';
import htCn from './htCn';
import common from './common';


Vue.use(Vuex);

// 用法见文档 
const createPersisted = createPersistedState({
 key:'storeVuex',
 storage: window.localStorage || window.sessionStorage,
 reducer(val) {
		 return {
		 	// 只储存
		 	tk:( (tk)=>{
				var obj = {};
				for(var tkKey in tk){
					// 不存session系列，仅存内存里
					var noArr = ['flightMap','flightMapBack']
					if(noArr.indexOf(tkKey)<0){
						obj[tkKey] = tk[tkKey]
					}
					
				}
				return obj
			})(val.tk),
		 	hotel:val.hotel,
		 	train:val.train,
			htCn:val.htCn,
		 	access_token:val.access_token,
		 	user:val.user,
			common:val.common,
		 }
	 
 }


})

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
	  tk,
	  train,
	  common,
	  htCn,
  },
  plugins:  [createPersisted]
});
