import enLocale from 'element-ui/lib/locale/lang/en'
import langMain from './en/langMain.js'
const en = {
    message: {
		homeMsg:"Welcome to Bussiness Travel System"
    },
	// 主页面
   ...langMain, 
    // 其他业务入口
      
    // element
    el : enLocale.el
}
export default en;