import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import langMain from './cn/langMain.js'

const cn = {
    message: {
    	homeMsg:'欢迎您使用商旅系统',
    },
   // 主入口
   ...langMain,
   // 其他业务入口
   
   // element
    el : zhLocale.el
  }

export default cn;