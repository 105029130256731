import $conn  from './../../plugins/http/http.js'
const actions = {
	// 获取用户信息
	getUserInfo({dispatch,commit},payload){
		let {vm,param,cb,errorCb} = payload;
		return new Promise((resolve, reject) => {
			$conn.getConn('user.userInfo',{error:true})(param).then((res)=>{
				let obj = {};
				let data = res.data || {};
				// 存储用户详情
				vm.$common.sessionset('user_info',JSON.stringify(data));
				vm.$common.localset('user_info',JSON.stringify(data));
				// 调取ajax获取企业参数，并传入回调
				dispatch('getVipParamterList',payload)
				resolve(data);
			}).catch((err)=>{
				errorCb&&errorCb(err)
				reject(err)
			})
		})
	},
	
	
	// 获取企业参数  将数组转换为map
	getVipParamterList({dispatch,commit},payload){
		let {vm,cb,errorCb} = payload;
		return new Promise((resolve, reject) => { 
			$conn.getConn('commu.getVipParamterList',{jsfile:false})({}).then((res)=>{
				let obj = {};
				let data = res.data || [];
				for(let i=0; i<data.length; i++){
					if(data[i].paramNo){
						obj[data[i].paramNo] = data[i];
					}
				}
				// 执行回调
				cb&&cb();
				
				// 提交vuex 企业参数data
				commit('setVipParamterList',obj);
				
				resolve();
			}).catch((err)=>{
				errorCb&&errorCb(err)
				reject(err)
			})
		})
	},
	//初始化 个人中心的一些信息 退出时调用
	setInitialCenterInfo(context, payload){
		context.commit('setCommonInitial')
	}
	
}
export default actions;