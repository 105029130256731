var dictCommon = {};

// 退票原因 类型字典
dictCommon.parNoRefundConfig = function(type){
	var parNoType = {
			11001:{
				parNo: 130, // 机票正常单退票
			},
			11002:{
				parNo: 130, // 机票正常单退票
			},
			11003:{
				parNo: 130, // 机票正常单退票 自愿改签
			},
	}
	return  parNoType[type].parNo;
};
// 改签原因 类型字典
dictCommon.parNoChangeConfig = function(type){
	var parNoType = {
			11001:{
				parNo: 132, // 自愿改签
			},
			11002:{
				parNo: 132, // 非自愿改签
			},
			11003:{
				parNo: 132, // 非自愿改签
			},
	}
	return  parNoType[type].parNo;
};

// 退改签完成跳转对应详情路径 orderType 订单类型， type T退票 G改签

dictCommon.refundchangeConfig = function(orderType){
	var detailUrl = {
		
		11002: { // 机票正常单
			path: "/yjDetail/tkRefundDetail",
		},
		11003:{
			path: "/yjDetail/tkChangeDetail"
		},
		11010:{
			path: "/yjDetail/trainRefundDetail",
		},
		11011:{
			path: "/yjDetail/trainChangeDetail",
		}
	}
	return detailUrl[orderType].path;
}

dictCommon.detailConfigByChat = function(orderIdBychat){
	var detailMapPathUrl = {
		11:'/yjDetail/tkOrderDetail', // 机票正常单
		12:"/yjDetail/tkRefundDetail", // 机 tui
		13:"/yjDetail/tkChangeDetail", // 机 改
		21:"/yjDetail/trainDetail",  // 火 正
		22:"/yjDetail/trainRefundDetail",  // 火 退
		23:"/yjDetail/trainChangeDetail",  // 火 改
		31:"/yjDetail/htCnOrderDetail", // 酒 正
		32:"/yjDetail/htCnRefundDetail", // 酒 退
	}
	return detailMapPathUrl[orderIdBychat] || '';
}


// 详情字典
dictCommon.detailConfig = function(type) {
    // let vipParamterList=JSON.parse(sessionStorage.getItem('storeVuex')).common.vipParamterList
    // let isXak=vipParamterList[20033].value1 //"申请单模板。1标准版,2定制版,默认为1"
    // let pathUrl;
    // if(isXak==2){
    //     pathUrl='/xakCcsqdDetail'
    // }else{
    //     pathUrl='/ccsqdDetail'
    // }
	var orderDetailUrl = {
			11001: { // 机票正常单
				path: "/yjDetail/tkOrderDetail",
			},
			11002: { // 机票退票单
				path: "/yjDetail/tkRefundDetail",
			},
			11003: { // 机票改签单
				path: "/yjDetail/tkChangeDetail"
			},
			11004: {
				path: "/yjDetail/tkOrderDetail",
			},
			11005: {
				path: "/yjDetail/tkRefundDetail",
			},
			11006: {
				path: "/yjDetail/tkChangeDetail",
			},
            11007: { // 酒店订单详情
				path: "/yjDetail/htCnOrderDetail",
			},
            11008: { // 酒店退单详情
                path: "/yjDetail/htCnRefundDetail"
              },
			11009: {
				path: "/yjDetail/trainDetail"
			}, // 火车正常单详情
			11010: {
				path: "/yjDetail/trainRefundDetail"
			}, // 火车退票单详情
			11011: {
				path: "/yjDetail/trainChangeDetail"
			}, // 火车改签单详情
			11012: {
				path: ""
			},
			11013: {
				path: ""
			},
			11014: { //代客泊车详情
				path: "/yjDetail/parkDetail"
			},
			11018: { // 用车详情
				path: "/yjDetail/carDetail"
			},
			11019: {
				path: ""
			},
			11099: { // 出差申请单详情
				path: '/ccsqdDetail'
			},
            11098:{ //变更单详情
                path: '/changeCcsqdDetails'
            },
            10918:{ // 会议会展详情
                path: '/yjDetail/miceDetail'
            }
		};
		return orderDetailUrl[type].path;
	
};
// 退票单路径
dictCommon.orderRefundConfig = function(type){
	var orderRefundUrl = {
		11001:{
			path: '/yjEdit/tkRefund', // 机票正常单退票
		},
		11002:{
			path: '/yjEdit/tkRefund', // 机票部分退 退票
		},
		11003:{
			path: '/yjEdit/tkRefund', // 机票改签单退票
		},
		11004:{
			path: '/yjEdit/tkRefund', // 国际机票正常单退票
		},
		11005:{
			path: '/yjEdit/tkRefund', // 国际机票部分退 退票
		},
		11006:{
			path: '/yjEdit/tkRefund', // 国际机票改签单退票
		},
		11009:{
			path: '/yjEdit/trainRefund', // 火车票正常单退票
		},
		11010:{
			path: '/yjEdit/trainRefund', // 火车票部分退单退票
		},
		11011:{
			path: '/yjEdit/trainRefund', //火车票改签单退票
		},
        11007:{
            path:'/yjTravel/book/htCnRouter/htRefundRooms'//酒店退房
        }	
	}
	return orderRefundUrl[type].path;
};
// 改签单路径
dictCommon.orderChangeConfig = function(type){
	var orderChangeUrl = {
		11001:{
			path: '/yjEdit/tkChange', // 机票正常单改签
		},
		11002:{
			path: '/yjEdit/tkChange', // 机票部分退 改签
		},
		11003:{
			path: '/yjEdit/tkChange', // 机票改签单
		},
		11004:{
			path: '/yjEdit/tkChange', // 国际机票正常单改签
		},
		11005:{
			path: '/yjDetail/tkChange', // 国际机票部分退 改签
		},
		11006:{
			path: '/yjEdit/tkChange', // 国际机票改签单
		},
		11009:{
			path: '/yjEdit/trainChange', // 火车票正常单改签
		},
		11010:{
			path: '/yjEdit/trainChange', // 火车票部分退 改签
		},
		11011:{
			path: '/yjEdit/trainChange', //火车票改签单退票
		},
	}
	return orderChangeUrl[type].path;
};


dictCommon.applyRouteConfig=function(){
	var configRoute = {
		//11001: '12711', //国内机票正常单
		//	11002 : '', //国内机票退票单
		//11003: '12712', //国内机票改签单
		//11004: '12732', //国际机票正常单
		//	11005 : '', //国际机票退票单
		//11006: '12733', //国际机票改签单
		//11007: '12715', //酒店正常单
		//	11008 : '', //酒店退单
		//11009: '12713', //火车票正常单
		//	11010 : '', //火车票退票单
		//11011: '12714', //火车票改签单
		//11012: '12722', //机场服务订单
		//	11013 : '', //接送机订单
		//11014: '12719', //代客泊车订单
		//	11015 : '', //保险订单
		//	11016 : '', //结欠单
		//	11017 : '', //结算单
		// 11018: '12718', // 用车订单
		// 11019: '12725', //  代办值机
		// 11020: '', // 机场服务退票单
		// 11024: '12735', //餐饮订单
		11099: 11099, //
	};
	// 业务类型
	/**
	 *  
	 11001   国内机票正常单
	 11002   国内机票退票单
	 11003   国内机票改签单
	 11004   国际机票正常单
	 11005   国际机票退票单
	 11006   国际机票改签单
	 11007   国内酒店正常单
	 11008   国内酒店退单
	 11009   火车票正常单
	 11010   火车票退票单
	 11011   火车票改签单
	 11012   机场服务订单
	 11013   接送机订单
	 11014   代客泊车订单
	 11015   保险订单
	 11016   结欠单
	 11017   结算单
	 11018   用车
	 11019   代办值机订单
	 11020   机场服务退单
	 11021   火车票抢票单
	 11022   国际酒店正常单
	 11023   国际酒店退单
	 11024   餐饮订单
	 11025   餐饮退单
	 11097   其他
	 11098   变更单
	 11099   出差申请单
	 *
	 **/
	

	
}


export default dictCommon