import { Message ,Notification,MessageBox } from 'element-ui';

// $message 的再封装
const notify = option => Notification(Object.assign({ customClass: 'notify_class' }, option))
const message = option => Message(Object.assign({ customClass: 'notify_class' }, option))
const messageBox = option =>MessageBox(Object.assign({customClass: 'notify_class'},option))
const optionsBox = (options, type) => {
  if (typeof options === 'string') {
    options = { message: options }
  }
  options.type = type
  return options
}
let typeArr = ['success', 'warning', 'info', 'error']
Object.keys(Notification).forEach(type => {
  if (typeArr.includes(type)) {
    notify[type] = options => notify(optionsBox(options, type))
    message[type] = options => message(optionsBox(options, type))
	messageBox[type] = options => messageBox(optionsBox(options, type))
  } else {
    notify[type] = Notification[type]
    message[type] = Message[type]
	messageBox[type] = MessageBox[type]
  }
})

export default{notify,message,messageBox}