<template>
	<div class="city-main" v-if="visible">
		<div class="cityModal">
			<div class="cityHeader">
				<p>请选择城市！</p>
				<p>
					<span class="el-icon-close fontIcon" @click="cancel"></span>
				</p>
			</div>
			<div class="cityContent">
				<div class="header">
					<p>根据贵公司规定，您本次出行可选择以下城市:</p>
					<p>暂无城市数据可选择！请先选择出差申请单后，再选择城市</p>
				</div>
				<div class="content">
					<div class="con1">
						<!-- 机票 -->
						<div class="con1-item"  v-if="paramsData.proType==10901" >
							<el-radio class="itemRadio"  
								v-for="(item, index) in currentCity" 
								:key="item.airport"
								:label="item.airport"
								:value="item"
								v-model="chooseCity"
								@change="getSelectInfo(index)"
								>
								<span>{{item.cityName + '('+(item.airportName || '')+''+item.airport+')'}}</span>
							</el-radio>
						</div>
						<!-- 酒店 -->
						<div  class="con1-item"  v-if="paramsData.proType==10903" >	
							<el-radio class="itemRadio"  
								v-for="(item, index) in currentCity" 
								:key="item.cityId" 
								:label="item.cityId"
								:value="item"
								v-model="chooseCity"
								@change="getSelectInfo(index)"
								>
								<span>{{item.cityName}}</span>
							</el-radio>
						</div>
					</div>
				</div>
			</div>
			<div class="cityFooter">
				<el-button  @click="cancel">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</div>
		</div>
	</div>
</template>

<script>

	export default{
		name: 'CityStation',
		data() {
			return {
				city:{}, // 出差申请单所有数据
				currentCity:[], // 当前城市数据
				paramsData: {
					cityType: 1, // 出发到达城市  机票必传
					cityId:'', // 城市id  酒店选中必传
					szm:'', // 城市三字码  机票选中行必传
					proType:10901, // 模块类型  必传
				},	

				chooseCity:'',// 选中城市
				
				cityIndex: 0, //选中城市索引
			}
		},
		props:{
			/*选中的出差申请单信息*/
			ccsqdInfo:{
				type: Object,
				default(){
					return {};
				}
			},
			/*传入进来的城市信息 主要包含 城市id 三字码 及 业务类型*/
			paramsInfo:{ // 城市参数信息
				type: Object,
				default(){
					return {};
				}
			},
			/*组件的隐藏显示*/
			visible:{
				type: Boolean,
				default: false,
			},
			/*出差申请单行程控制方式*/
			changeRouteRole:{
				type: [String,Number],
				default: '0',
			},
		},
		watch:{
			/*监听初始参数变化*/
			ccsqdInfo: {
				handler(newInfo, oldInfo){
					this.city = newInfo;
					// this.$forceUpdate()
				}, 
				immediate: true,
				deep:true,	　 
			},
			visible:{
				handler(bool){
					if(bool){
						setTimeout(()=>{
							this.getCityStatus(this.ccsqdInfo,this.paramsInfo);
						},100)
					
						
					}
				},
				immediate: true,
			},
			paramsInfo:{
				handler(val){
					
					this.paramsData.cityType = val.cityType;
					this.paramsData.cityId = val.cityId;
					this.paramsData.szm = val.szm;
					this.paramsData.proType = val.proType; 
					if(val.proType == 10903){
						this.chooseCity = val.cityId;
					}else{
						this.chooseCity = val.szm;
					}
					// this.remove()
				},
				immediate: true,
				deep:true,
			},
			
		},
		created(){
			this.city = this.ccsqdInfo;
		},
		methods: {
			// 切换不同模块时，清除之前数据
			remove(){
				this.city = {};
				this.currentCity = [];
			},
			// 选择城市
			getCityStatus(){
		
					// 获取城市信息

				if(!this.city.ccsqdId){
					return
				}
				if(this.changeRouteRole==0){
					return
				}else if(this.changeRouteRole==2){
					this.getAllCity(this.city)
				}else if(this.changeRouteRole==3){
					this.getAllCity(this.city,this.paramsData.cityType)
				}
			
			},
			// 全部城市数据
			getAllCity(city,type){
				// 出发，到达行程信息
				let allCity = []; 
				// 所有行程信息
				let cityList = [];
				// 遍历后的行程信息
				let newCityList = [];
				// 最后被使用的结果数组
				let newResultList = [];
				
				let proType = this.paramsData.proType;
				cityList = city.detail.allRouteList || [];
				
				for(var k=0;k<cityList.length;k++){
					var cityItemArray = cityList[k];
					if(this.$common.isArray(cityItemArray)){
						for(var j=0;j<cityItemArray.length;j++){
							if(cityItemArray[j]['proType'] == proType || cityItemArray[j]['proType'] == '---'){
								newCityList.push(cityItemArray[j])
							}
						}
					}
				}
				
				
				let fromCity = []; // 出发城市
				let toCity = []; // 到达城市
				newCityList.forEach((item,index)=>{
					let fromObj = {};
					let toObj = {};
					
					fromObj = {
						airport: item.departAirport,
						airportName: item.departAirportName,
						cityId: item.departCity,
						cityName: item.departCityName,
					}
					fromCity.push(fromObj);
					
					if(proType!=10903){
						toObj = {
							airport: item.arriveAirport,
							airportName: item.arriveAirportName,
							cityId: item.arriveCity,
							cityName: item.arriveCityName,
						}
						toCity.push(toObj);
					}
					
				})
					// 选择出发
					if(type==1){
						allCity = fromCity;
					// 选择到达
					}else if(type == 2){
						allCity = toCity
					// 出发和到达
					}else{
						allCity = fromCity.concat(toCity)
					}	
				
				// 中铁单点兼容问题
				// 处理兵器单点行程的 多城市问题
				// if(city.detail && city.detail.totalTrip && city.detail.totalTrip['routeId'] == city.routeId){
				// 	var ddcityList =city.detail.totalTrip.cityList || [];
				// 	for(var k = 0;k< ddcityList.length;k++ ){
				// 		var cityItem = ddcityList[k] || {};
				// 		allCity.push({
				// 			airport: cityItem.airportCode,
				// 			airportName:cityItem.airportName,
				// 			cityId:cityItem.cityId,
				// 			cityName:cityItem.cityName,
				// 			selectType: type,
				// 		});
				// 	}
					
				// }
				
				// 去重操作
				
				allCity.forEach((a)=> {
					
				    let check = newResultList.every((b)=> {
						let bool = false;
						if(proType==10903){
							// 酒店比较用城市Id	
							bool = (a.cityId != b.cityId);
						}else{
							// 机票比较 机场三字码（会出现一个城市多个机场问题）
							bool = (a.airport!=b.airport)
						}
				            return bool;
						
				        }) 
				        check ? newResultList.push(a) : ''
				}) 
				this.currentCity = newResultList;	
			},
			// // 出发 到达 城市数据
			// getCurrentCity(city,type){
			// 	// 所有行程信息
			// 	let cityList = [];
			// 	// 遍历后的行程信息
			// 	let newCityList = [];
			// 	// 出发城市
			// 	let fromCity = []; 
			// 	// 到达城市
			// 	let toCity = []; 
			// 	cityList = city.detail.allRouteList || [];
				
			// 	cityList.forEach((item,index)=>{
			// 		let fromObj = {};
			// 		let toObj = {};
			// 			if(this.paramsData.proType==10903){
			// 				if(item.departCity){
			// 					fromObj = {
			// 						airport: item.departAirport || '',
			// 						airportName: item.departAirportName,
			// 						cityId: item.departCity,
			// 						cityName: item.departCityName,
			// 					}
			// 					fromCity.push(fromObj);
			// 				}
			// 			}else{
			// 				if(item.departCity && item.departAirport){
			// 					fromObj = {
			// 						airport: item.departAirport,
			// 						airportName: item.departAirportName,
			// 						cityId: item.departCity,
			// 						cityName: item.departCityName,
			// 					}
			// 					fromCity.push(fromObj);
			// 				}
			// 			}
						
			// 			if(item.arriveCity && item.arriveAirport){
			// 				toObj = {
			// 					airport: item.arriveAirport,
			// 					airportName: item.arriveAirportName,
			// 					cityId: item.arriveCity,
			// 					cityName: item.arriveCityName,
			// 				}
			// 				toCity.push(toObj);
			// 			}
						
			// 	})
			// 	if(type==1){
			// 		fromCity.forEach((a)=> {
			// 		    var check = newCityList.every((b)=> {
			// 		            return a.cityId !== b.cityId;
			// 		        }) 
			// 		        check ? newCityList.push(a) : ''
			// 		}) 
			// 	}else{
			// 		toCity.forEach((a)=> {
			// 		    var check = newCityList.every((b)=> {
			// 		            return a.cityId !== b.cityId;
			// 		        }) 
			// 		        check ? newCityList.push(a) : ''
			// 		}) 
					
			// 	}
			// 	this.currentCity = newCityList;	
			// },
			// 当前城市下标
			getSelectInfo(e){
				this.cityIndex = e; 
			},
			cancel(){
				this.$emit('cancel',false)
			},
			determine(){
				var i = this.cityIndex;
				if(this.currentCity.length==0){
					this.cancel();
					return 
				}
				let obj = {
						cityName: this.currentCity[i].cityName, //  出发城市
						threeCode: this.currentCity[i].airport, //  出发城市三字码
						cityInput: this.currentCity[i].cityName + (this.currentCity[i].airportName? ('('+this.currentCity[i].airportName +')'):'') +'('+this.currentCity[i].airport+')', //
						airportName: this.currentCity[i].airportName, 
						cityId: this.currentCity[i].cityId,
						cityType:this.paramsData.cityType
					}
				this.$emit('determine',obj)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.city-main{
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.2);
		z-index: 15;
		.cityModal{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 600px;
			height: 400px;
			background-color: #FFFFFF;
			transform: translate(-50%,-50%);
			.cityHeader{
				display: flex;
				justify-content: space-between;
				padding: 15px 20px ;
				background-color: rgba(100,100,100,0.2);
				font-size: 16px;
				font-weight: bold;
				.fontIcon{
					font-size: 18px;
					cursor: pointer;
				}
				.fontIcon:hover{
					color: #0076F5;
				}
			}
			
			.cityContent{
				height: 290px;
				padding: 20px;
				overflow: hidden;
				.header{
					text-align: left;
					line-height: 20px;
					margin-bottom: 10px;
				}
				.content{
					height: 220px;
					overflow-x: hidden;
					overflow-y: auto;
					background-color: rgba(200,200,200,0.2);
					
					.con1{
						width: 100%;
						text-align: left;
						padding: 10px;
						.con1-item{
							width: 100%;
							
							.itemRadio{
								line-height: 30px;
								width: 100%;
								padding-left: 10px;
							}
							.itemRadio:hover{
								color: #FFFFFF; 
								background-color: rgba(0,0,0,0.2);
								transition: all 0.3s ease;
							}
						}
					}
					
				}
			}
			.cityFooter{
				margin: 14px ;
			}
		}
	}
</style>
