<template>
	<el-card class='ci-main' :style="{'margin-top': marginTop}">
		<div class="ci-title">联系人</div>
		<div class="marT30 textLeft">
			<span style="width: 145px;" class="font15 inBlock">{{linkData.linkman}}</span>
			<span class="font15 inBlock">{{linkData.linkMobile || linkData.linkTel}}</span>
		</div>
	</el-card>
</template>

<script>
	export default{
		name: 'ContactInfo',
		props: {
			marginTop:{
				type: String,
				default: '20px',
			},
			linkInfo:{
				type: Object,
				default(){
					return {}
				},
			}
		},
		data() {
			return {
				linkData: {},
			}
		},
		mounted(){
			this.getLinkMan();
		},
		methods: {
			getLinkMan() {
				let userInfo = this.$common.getUserInfo();
				let empInfo = userInfo.empInfo;
				if(this.linkInfo && this.linkInfo.linkman && (this.linkInfo.linkman || this.linkInfo.linkTel)){
					this.linkData = {
						linkman: this.linkInfo.linkman,
						linkMobile: this.linkInfo.linkMobile,
						linkTel: this.linkInfo.linkTel,
					}
				}else{
					this.linkData = {
						linkman: empInfo.empName,
						linkMobile: empInfo.phoneNumber,
						linkTel: empInfo.phoneNumber,
					}	
				}
				
			}
		}
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.ci-main{
		font-size: 15px;
		font-family: '微软雅黑';
		font-weight: 400;
		color: #424242;
		.ci-title{
			font-size: 24px;
			font-weight: 600;
			line-height: 22px;
			text-align: left;
		}
		/deep/ .el-card__body{
			padding: 30px;
		}
		.flex{
			display: flex;
		}
		.textLeft{
			text-align: left;
		}
		.marT30{
			margin-top: 30px;
		}
		.inBlock{
			display: inline-block;
		}
		.font15{
			font-size: 15px;
		}
	}
</style>
