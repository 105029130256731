/*
 *   本文件为 国际酒店需求单路由
 *
 */ 

const routeInHt = [
	{
		path: 'inHtRouter',
		component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/international/interHt/InHt"),
		redirect: 'inHtRouter/InHtEdit' ,
		children: [
			{   
				path: 'inHtEdit',
				name:'InHtEdit',
				component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/international/interHt/InHtEdit"),
				meta:{
					title:"国际酒店需求单"
				}
			},	   
		]
	},
	
	
]

export default routeInHt