<template>
	<div class="cp-main">
		<el-dialog
			class="text-left"
			title="<span>选择企业员工</span>"
			top="25vh"
			:fullscreen="maxScreen" 
			:modal="true"
			:close-on-click-modal="false"
			:modal-append-to-body="true"
			:append-to-body="true"
			:visible.sync="visible"
			:show-close="false"
			width="900px"
			
			>
			<div slot="title">
				<div class="flex between  ">
					<div class="font18">选择员工</div>
					<div>
						<span v-show="maxScreen" class="el-icon-minus font18 marR10 pointer" @click="changeMaxScreen(false)"></span>
						<span  v-show="!maxScreen" class="el-icon-s-platform font18 marR10 pointer" @click="changeMaxScreen(true)"></span>
						<span class="el-icon-close font18 pointer" @click="cancel"></span>
					</div>
				</div>
			</div>
			<div class="tab-qh" >
				<el-button type="button" :class="{primary:tabIndex==1}" @click="switchType(1)">部门</el-button>
				<el-button type="button" :class="{primary:tabIndex==2}" @click="switchType(2)">员工</el-button>
				<el-button type="button" :class="{primary:tabIndex==3}" v-if="(tripType == 2 || bookOutside) && clkShow" @click="switchType(3)">常旅客</el-button>
		
			</div>
			<div class="dialog-content">
				<div class="flex" :style="{height:maxScreen?cheight+'px':298+'px'}">
					<div class="width34 cp-con" v-if="tabIndex==1" :style="{height:maxScreen?cheight+'px':298+'px'}">
						<p class="cp-p-title">部门列表</p>
						<el-tree class="cp-con-tree" 
							:style="{height:maxScreen?(cheight-42)+'px':256+'px'}" 
							:data="departmentList" 
							v-if="tabIndex==1"  
							@node-click="handleNodeClick"
						></el-tree>
						
					</div>
					<div class="width66 flex border" :class="{width100:tabIndex!=1}">
						<div class="width50 cp-yg  borderR">
							<div class="border-btm padBtm10">
								<p class="cp-yg-p flex between" ><span>待选人员</span> <span class="colorBlue pointer" @click="allChoose">全选</span></p>
								<el-input v-model="searchNameCode" placeholder="请选择人员姓名/编号" style="width: 100%; padding:0 15px;" @input="seacrhCorpPerson"></el-input>
							</div>
							<div class="cxr-list-box" :style="{height:maxScreen?(cheight-86)+'px':214+'px'}">
								<div v-for="(item, index) in empList" :key="item.empId+''+index+''+item.contactsId" class="cxrItem " @click="chooseCorpPerson(item, index)">
									<span class="cxrName" :title="calcTitle(item)" v-if="tabIndex!=3">{{item.empName +'('+(!item.empNo?'':item.empNo) +'/'+(item.deptName||'')+')'}}</span>
									<span class="cxrName" :title="calcTitle(item)" v-if="tabIndex==3">{{item.empName}}</span>
									<span v-show="empIdList.indexOf(item.empId)<0" class=" el-icon-plus cxrIcon" style="padding-right: 15px;"   v-if="tabIndex!=3"></span>
									<span v-show="contactsIdList.indexOf(item.contactsId)<0" class=" el-icon-plus cxrIcon" style="padding-right: 15px;"   v-if="tabIndex==3"></span>
								</div>
							</div>
						</div>
						<div class=" width50 cp-yg w215 hidden">
							<p class="cp-yg-p flex between border-btm">已选人员 {{chooseEmpList.length}} 人 <span class="colorBlue pointer" @click="removeAllChoose">清空</span></p>
							<div class="cxr-list-box" :style="{height:maxScreen?(cheight-86)+'px':256+'px'}">
								<div v-for="(item, index) in chooseEmpList" :key="item.empId+''+index+''+item.contactsId" class="cxrItem" @click="removeCorpPerson(item, index)">
									<span class=" cxrName" :title="calcTitle(item)">
									{{item.empName}} {{item.contactsId?'(常)':'('+(!item.empNo?'':item.empNo) +'/'+(item.deptName||'')+')'}}
									</span>
									<span class=" el-icon-minus cxrIcon" style="padding-right: 15px;"  ></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<el-pagination
				v-if="tabIndex==2 && empList.length>0"
				background
				@current-change="handleCurrentChange"
				:page-size="pageSize"
				:current-page="pageNum"
				layout="prev, pager, next"
				:total="total">
			</el-pagination>
			<span slot="footer" class="dialog-footer">
			    <el-button @click="cancel">取 消</el-button>
			    <el-button type="primary" @click="determine">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: "CxrList",
		props: {
      // 因公，因私 控制常旅客回显
      tripType:{
        type: Number,
        default(){
        	return 1
        }
      },
			// 注入的出行人列表
			cxrList:{
				type:Array,
				default(){
					return []
				}
			},
			// 无法进行选择的项
			noCxrList:{
				type:Array,
				default(){
					return []
				}
			},
			// 最大出行人数量
			maxLength:{
				type:Number,
				default:9,
			},
			// 控制 显示隐藏
			visible:{
				type: Boolean,
				default() {
					return false
				}
			},
			// 显示常旅客
			clkShow:{
				type: Boolean,
				default() {
					return true
				}
			},
			// 新建出差申请单 判断是否是出差人，随行人， sxr 0否 1是
			ccsqdSxrObj:{
				type: Object,
				default(){
					return {}
				}
				
			}
		},
		data() {
			return {
				departmentList: [], // 部门列表
				searchNameCode:'', // 搜索员工编码
				empList:[], //企业员工列表
				chooseEmpList:[], // 选中企业员工列表
				empIdList: [], // 记忆选中企业员工列表
				maxScreen: false, // 出行人最大框
				
				tabIndex: 1, //切换 1按部门 2员工 3常旅客

				bookOutside:0, // 是否能为外来人订票
				contactsIdList:[], //待选常旅客列表
				
				pageNum:1, // 分页数据
				pageSize:10, //分页展示条数
				total: 0, //总条数
				
				cheight:0,
				deptId:'', //部门顶级id
			}
		},
		mounted() {
			var userInfo = this.$common.getUserInfo() ;
			var empInfo = userInfo.empInfo || {};
			this.bookOutside = empInfo.bookOutside;
		},
		watch:{
			cxrList:{
				immediate: true, // 立即执行
				deep:true, // 深度监听
				handler(val) {
				  this.handleCxrList(val)
				},
				
			},
			visible(bool){
				if(bool){
					this.tabIndex = 1;
					this.queryDepartment();
				}
			},
      
		},
		methods: {
			// 切换类型
			switchType(type){
				// 防止单项重复点击
				if(type == this.tabIndex){
					return 
				}
				this.empList = [];
				this.tabIndex = type;
				this.queryDepartment();
				// 按部门查找不具备搜索功能
				if(this.tabIndex == 1){
					this.searchNameCode = '';
				}
			},
			
			// 出行人列表处理
			handleCxrList(cxrList){
				let cxrListCopy = this.$common.deepCopy(cxrList)
				// 清除上一次缓存信息
				this.contactsIdList = [];
				this.empIdList = [];
				this.chooseEmpList = cxrListCopy;
				
				if(this.chooseEmpList.length>0){
					// 此处处理选中出行人带有常旅客时区分开
					this.chooseEmpList.map(item=>{
						if(item.contactsId){
							this.contactsIdList.push(item.contactsId)
						}else if(!item.contactsId && item.empId){
							this.empIdList.push(item.empId)
						}
						return item
					})	
					
				}
				
			},
			// 计算title
			calcTitle(item){
				return item.empName +'('+(!item.empNo?'':item.empNo) +'/'+(item.deptName||'')+')'
			},
			// 是否全屏显示
			changeMaxScreen(bool){
				this.maxScreen = bool;
				this.cheight = document.body.clientHeight - 280;
			},
			// 选择部门，获取相应员工
			handleNodeClick(data) {
				this.deptId = data.deptId;
				if(data.type == 'onlyExpand'){
					return
				}
				let queryType = data.type  || 'deptId';
				let obj = {};
				// 单个部门 和  指定人员 使用   empId  ；
				// 指定职级使用  rankCode  
				obj[queryType] =  data[queryType];
				
				this.$conn.getConn('commu.deptEmpFindList',{jsfile:false})(obj,(res)=>{
					let data = res.data || {};
					let list = data.emplist || [];
					this.empList = this.handleEmpList(list,this.empIdList,this.noCxrList)
				},(err)=>{
					this.$message({
						message:err.tips,
						type: 'error'
					})
				})
			},
			// 查询部门 类型查询 this.tabIndex ， 1部门，2员工 ，3常旅客 
			queryDepartment(){
				let queryParams = {
					queryStr: this.searchNameCode,
					deptId: "",
					queryType: this.tabIndex,
					pageNum: this.pageNum,
					count: 10,
				}
				
				this.$conn.getConn('commu.empFindList3')(queryParams,(res)=>{
					let data = res.data || {};
					if(this.tabIndex==1){
						// 部门数据
						let deptList = data.deptBeans || [];
						// 指定人员
						let bookEmpBean = data.bookEmpBean || {};
						if(bookEmpBean.empId){
							let bookObj = {
								parentId:0,
								deptName:"指定人员",
								deptId:bookEmpBean.empId
							}
							deptList.push(bookObj)
						}
						// 指定职级
						let empRankList = data.empRankBeans || [];
						if(empRankList.length>0){
							let rankObj = {
								parentId:0,
								deptName:"指定职级",
								deptId:1,
								type:'onlyExpand'
							}
							deptList.push(rankObj)
							empRankList = empRankList.map((empRankItem)=>{
								return {
									...empRankItem,
									parentId:1,
									deptName:empRankItem.rankName,
									deptId:empRankItem.rankId,
									type:'rankCode'
								}
							})
							deptList = deptList.concat(empRankList);
						}
						this.handleDepartTreeList(deptList)
					}else if(this.tabIndex==2){
						let empPageInfo = data.empPageInfo || {};
						let list = empPageInfo.list || [];
						this.total = empPageInfo.total;
						this.pageNum = empPageInfo.pageNum;
						list.map(item=>{
							return item
						})
						this.empList = this.handleEmpList(list,this.empIdList,this.noCxrList)
					}else if(this.tabIndex==3){
						let contactPageInfo = data.contactPageInfo || [];
						let list = contactPageInfo.map(item=>{
							item.empName = item.chName;
							return item
						})
						this.empList = this.$common.deepCopy(list);
					}
									
				},(err)=>{
					this.$message({
						message:err.tips,
						type: 'error'
					})
				})
			},
			// 分页查询出行人信息
			handleCurrentChange(e){
				this.pageNum = e;
				this.queryDepartment();
			},
			// 重排部门树
			handleDepartTreeList(list){
				list = list || []; 
				let treeList = [];
				// 重点在这几句
				// 通过info建立了id=>node的映射
				let info = list.reduce((map, node) => {
				        return map[node.deptId] = node,node.id=node.deptId,node.label=node.deptName, node.children = [], map
				    }, {})
					 
				// 最后arr就是我们转换成功的树结构
				treeList = list.filter(node => {
					
					if (info[node.parentId]) {
						// 将对应的node放入对应的对象里面去，因为它是浅拷贝，所以会影响原来的数组
						// 进而组装成一个树结构，这块大家打印出来看看就明白了了
						info[node.parentId].children.push(node)
					}
				    // 将没parentId的node返回组成一个新数组
				    // 没有parentId代表着它是根元素 或  parentId == 0   或  找不到父节点就是根节点
				  
				    return node.parentId == '0' || !node.parentId || !info[node.parentId]  // 为 true会返回这个数据，为false则不会返回
				})	
				this.departmentList = treeList;
				return treeList
			},
			// 选中企业员工
			chooseCorpPerson(item, index){

				// 如果该人员，已被选中，则不被再次选中
				if(this.empIdList.length>0 && this.empIdList.indexOf(item.empId)>-1 && this.tabIndex!=3){
					return false
				}
				if(this.contactsIdList.length>0 && this.contactsIdList.indexOf(item.contactsId)>-1 && this.tabIndex==3){
					return false
				}
		
				if((this.empIdList.length + this.contactsIdList.length) < this.maxLength){
					if(this.tabIndex!=3){
						this.empIdList.push(item.empId)
					}else{
						this.contactsIdList.push(item.contactsId)
					}
					this.chooseEmpList.push(item)
					
				}else{
					
					this.$message({
						type: 'info',
						message: '已达到最大选择人员数:'+this.maxLength
					})
				}
					
			},
			// 清除选中员工
			removeCorpPerson(item, index) {
				// 常旅客 与 员工 删除时分别进行
				if(item.contactsId){
					this.contactsIdList.splice(this.contactsIdList.indexOf(item.contactsId),1)
				}else{
					this.empIdList.splice(this.empIdList.indexOf(item.empId),1)
				}
				this.chooseEmpList.splice(index,1)		
			},

	
			// 搜索员工列表  
			seacrhCorpPerson(){
				if(this.tabIndex == 1){
					let obj = {
							  "deptId": this.deptId,
							  "empNo": this.searchNameCode,
							  "foreignType": 0,
							  "queryType": 1
							}
						this.$conn.getConn('commu.deptEmpFindList')(obj,(res)=>{
							let data = res.data || {};
							let list = data.emplist || [];
							this.empList = this.handleEmpList(list,this.empIdList,this.noCxrList)
							
						},(err)=>{
							this.$message({
								message:err.tips,
								type: 'error'
							})
						})	
				}else{
					let obj = {
						queryStr: this.searchNameCode,
						count: 10,
						pageNum: 1,
						queryType: this.tabIndex,
					}	
					this.$conn.getConn('commu.empFindList3')(obj,(res)=>{
						let data = res.data || {};	
						let list = [];
						if(this.tabIndex == 3){
							list = data.contactPageInfo.map(item=>{
								item.empName = item.chName;
								return item
							})
							this.empList =this.$common.deepCopy(list);
						}else{
							let empPageInfo = data.empPageInfo || {};
							list = empPageInfo.list || [];
							this.pageNum = empPageInfo.pageNum;
							this.total = empPageInfo.total;
							this.empList = this.handleEmpList(list,this.empIdList,this.noCxrList);
						}
						
					},(err)=>{
						this.$message({
							message:err.tips,
							type: 'error'
						})
					})
				}
				
			},
			// 全选
			allChoose(){
				
				let wxzList = []; //未选中出行人
				if(this.empList.length>0){
					this.empList.forEach(item=>{
						if(this.tabIndex==3){
							if(this.contactsIdList.indexOf(item.contactsId)==-1){
								wxzList.push(item)
							}
						}else{
							if(this.empIdList.indexOf(item.empId)==-1){
								wxzList.push(item)
							}
						}
					})
				}
				if(this.maxLength-(this.chooseEmpList.length + wxzList.length)<0){
					this.$message({
						type:'warning',
						message:'出行人最多可选'+this.maxLength+'人，超出最大出行人数，无法全选'
					})
					return
				}
				if(this.empList.length>0){
					this.empList.forEach((item)=>{
						if(this.tabIndex == 3){
							if(this.contactsIdList.indexOf(item.contactsId)!=-1){	
							}else{
								this.contactsIdList.push(item.contactsId);
								this.chooseEmpList.push(item)
							}
						}else{
							if(this.empIdList.indexOf(item.empId)!=-1){
								
							}else{
								this.empIdList.push(item.empId)
								this.chooseEmpList.push(item)
							}
						}
						
						
					})
				}
					
				
				
			},
			// 清空所有选中
			removeAllChoose(){
				this.empIdList = [];
				this.contactsIdList = [];
				this.chooseEmpList = [];
				
			},
			// 取消 关闭视口
			cancel(){
				this.searchNameCode = '';
				this.empList = [];
				this.$emit('cancleCxrList',false)
			
				
			},
			// 确定 
			determine() {
				// 返回乘车人列表信息
				let list = this.chooseEmpList;
			
				list.map((item,index)=>{
					/* 此处为新建出差申请单判断 ，ccr 出差人 与 sxr随行人 sxr 0否 1是*/
					if(JSON.stringify(this.ccsqdSxrObj)!="{}"){
						if(this.ccsqdSxrObj.isSxr == 0){
							item.sxr = 0; //
						}else if(this.ccsqdSxrObj.isSxr == 1){
							item.sxr = 1;
						}
					}
					item.costCenterId = item.costCenterId || "",
					item.costCenterName = item.costCenterName || "",
					item.costCenterCode = item.costCenterCode || "",
					item.exPerson = item.contactsId?1:0; // 是否是 外来人 0否 1是
					item.useCardType = item.useCardType || '1'; // 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
					item.cxrType = item.contactsId? '2' :'1';  //当前人的出行人类型   1.员工  2.常旅客 3. 其他
					item.psgType = item.psgType || '1'; // 默认 1  1成人 2儿童 3婴儿 
					item.cxrName = item.empName; //当前出行人的中文名字
					item.foreignType = item.foreignType;
					return item
				})
				this.returnCxrList(list)
			},
			// 返回出行人信息
			returnCxrList(list){
				this.$emit('chooseCxrList',list,false);
				this.searchNameCode = '';
				this.empList = [];
				
			},
			/*
			*  处理员工数据，
			*  功能1： 检测员工是否被选中
			*  功能2： 检测员工是否为不可选之人
			*  功能3： 处理为出行人为员工类型
			* 
			*  参数1： empList 【array】 待处理数据
			*  参数2： empIdList 【Array】已选中员工id集合
			*  参数3： noCxrList 【array】 无需显示之人
			* 
			* 
			* 
			*  return newList【array】 新的处理后的数据
			* 
			* **/ 
			handleEmpList(empList,empIdList,noCxrList){
				var newList = [];
				newList = empList.map((item,index)=>{
					item.cxrType = item.contactsId? 2 : 1; // 标记为员工 此处不在需要处理出行人类型
					return item
				}).filter((fItem)=>{
					//  处理不需要显示的人，不显示
					function isManZu(empId,noCxrList){
						let bool = true;
						let newArr = noCxrList.filter((item,index)=>{
							return item.empId == empId
						}) || []
						if(newArr.length>0){
							bool = false;
						}
						return bool
					}
						
					return isManZu(fItem.empId,noCxrList)
				}) || []
				return newList
			},
			
		}
	}
</script>

<style scoped lang="scss">
		
		
		.width34{
			width:34%;
		}
		.width66{
			width:66%;
		}
		.width100{
			width:100%;
		}
		.width50{
			width:50%;
		}

		/deep/ .is-fullscreen{
			min-width: 760px;
		}
		.pointer{
			cursor: pointer;
		}
		.font18{
			font-size: 18px;
		}
		.text-left{
			text-align: left;
		}
		.text-right{
			text-align: right;
		}
		.colorBlue{
			color: #4089FF;
		}
		.bg-c{
			background: #eee;
		}
		.inlineBlock{
			display: inline-block;
		}
		.padBtm10{
			padding-bottom: 10px;
		}
		.padTop20{
			padding-top: 20px;
		}
		.padTop10{
			padding-top: 10px;
		} 
		.padBtm20{
			padding-bottom: 20px;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		
		.line-h30{
			line-height: 30px;
		}
		.cp-yg {
			.cp-yg-p{
				padding:10px 15px;
			}
		} 
		
		.border{
			border: 1px solid #ccc;
		}
		.borderR{
			border-right: 1px solid #ccc ;
		}
		.border-btm{
			border-bottom: 1px solid #ccc ;
		}
		
		.cp-con{
			border: 1px solid #ccc;
			height: 298px;
		}
		.cp-con-tree{
			height: 256px;
			overflow-y: auto;
			overflow-x: hidden;
		}
	
		
		/deep/ .el-dialog__body{
			padding:10px 20px 20px 20px;
		}
		 .v-modal{
			z-index: 10000 !important;
		}
		 .el-dialog__wrapper{
			z-index: 10001 !important;
		}
	
		.cp-p-title{
			background: #eee;
			padding: 10px 0px 10px 10px;
			border-bottom: 1px solid #ccc;
		}
		
		.cxr-list-box{
			height: 206px;
			padding-top: 10px;
			overflow-y: auto;
			overflow-x: hidden;
			.cxrItem{
				padding:0 0 0 15px;
				cursor: pointer;
				display: flex;
				
				.cxrName{
					display: inline-block;
					width: calc(100% - 30px);
					padding:5px 0;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
				.cxrIcon{
					display: inline-block;
					width: 30px;
					line-height: 30px;
				}
			}
			.cxrItem:hover{
				background-color: #f0f0f0;
			}
			
		}
		.marLeft40{
			margin-left: 40px;
		}
		.marR10{
			margin-right:10px;
		}
		.tab-qh{
			width: 100%;
			padding:0 0 10px 0;
			font-size: 16px;
			.primary{
				color: #fff;
				background-color: #409eff;
				border-color: #409eff;
			}
			
		}
		.clk-xx{
			padding: 10px 0;
			height: auto;
			overflow-y: auto;
			.clkys{
				height: 26px;
				line-height: 26px;
				padding:0 10px;
			}
			.clkys:hover{
				background-color: #F5F7FA;
				cursor: pointer;
			}
		}
		
</style>

