/*   
 *
 *  调用封装
 *  使用axios请求封装
 *  定义三个方法：
 *   1. 无需登录调用
 *   2. 需要登录调用
 *   3. file文件上传
 *
 */ 
import Conn from './conn.js'
import Ajax from './request.js'

// 获取请求参数
function getRequest(str){
		var result = Conn.configMap || {};
		var arr = str.split('.');
		var attrName = '';
		for(var i=0;i<arr.length;i++){
			attrName = arr[i];
			result = result[attrName];
		}
		return result;
	}
// 调取接口
function getConn(str,funobj){//调用后台接口
		var req = getRequest(str);
		var $url = req.url;
		var method = req.method.toLowerCase();
		funobj = funobj || {};
		funobj['noNeedToken'] = req['noNeedToken'];
		funobj['timeout'] = req['timeout'];
		return (param,successFn,errorFn)=>{
			
			$url =  $url;
			return Ajax[method]($url,param,funobj,successFn,errorFn)
		}
	}

// var getFileConn = function(){
	
// }
//获取ur链接
function getUrl(str){//调用后台接口
		var req = getRequest(str);
		var $url = req.url;
		return $url
	}



export  default {getConn,getUrl } 





