
/*
 *   本文件为 火车票路由
 *
 */ 

const routeTr = [
	{
		path: 'trRouter',
		component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tr/Tr"),
		redirect: 'trRouter/trQuery' ,
		children: [
			{   
				path: 'trQuery',
				name:'TrQuery',
				component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tr/TrQuery"),
				meta:{
					title:"火车票查询"
				}
			},
			

			   
		]
	},
]
//  用于不带导航的路由
const  routeTrEdit = [
	{
		path: 'trEdit',
		name:'TrEdit',
		component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tr/TrEdit"),
		meta:{
			title:"火车票订单填写"
		}
	},
	{	//火车票退票页
		path: 'trainRefund',
		name:'TrainRefund',
		component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tr/TrainRefund"),
		meta:{
			title:"火车票退票"
		}
	},
	
	{   //火车票改签页
		path: 'trainChange',
		name:'TrainChange',
		component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tr/TrainChange"),
		meta:{
			title:"火车票改签"
		}
	},	
]
export {routeTr,routeTrEdit}