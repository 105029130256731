
/*
 *   本文件为 机票路由
 *
 */ 
// 用于带产品导航路由
const routeTk = [
	{
		path: 'tkRouter',
		component:  () => import(/* webpackChunkName: "tk" */ "./../../views/tk/Tk"),
		redirect: 'tkRouter/tkQuery' ,
		children: [
			{
				path: 'tkQuery',
				name:'TkQuery',
				component:  () => import(/* webpackChunkName: "tk" */ "./../../views/tk/TkQuery"),
				meta:{
					title:"机票查询"
				}
			},
			{
				path: 'TkZcQuery',
				component:  () => import(/* webpackChunkName: "tk" */ "./../../views/tk/TkQuery"),
				meta:{
					title:"政采机票查询"
				}
			},
			// {	//机票订单详情页
			// 	path: 'tkOrderDetail',
			// 	name:'TkOrderDetail',
			// 	component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tk/TkOrderDetail"),
			// },
			   
			// {	//机票退票页详情
			// 	path: 'tkRefundDetail',
			// 	name:'TkRefundDetail',
			// 	component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tk/TkRefundDetail"),
			// },
		
			// {	//机票改签页详情
			// 	path: 'tkChangeDetail',
			// 	name:'TkChangeDetail',
			// 	component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/tk/TkChangeDetail"),
			// },
		]
	}
	
]
//  用于不带导航的路由
const  routeTkEdit = [
	{
		path: 'tkEdit',
		name:'TkEdit',
		component:  () => import(/* webpackChunkName: "tk" */ "@/views/tk/TkEdit"),
		meta:{
			title:"机票订单填写"
		}
	},
	{	//机票申请退票页
		path: 'tkRefund',
		name:'TkRefund',
		component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/tk/TkRefund"),
		meta:{
			title:"机票申请退票"
		}
	},
	{	//机票申请改签页
		path: 'tkChange',
		name:'TkChange',
		component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/tk/TkChange"),
		meta:{
			title:"机票申请改签"
		}
	},
	
]

const routeTkDetail = [
	{	//机票订单详情页
		path: 'tkOrderDetail',
		name:'TkOrderDetail',
		component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/tk/TkOrderDetail"),
		meta:{
			title:"机票订单详情"
		}
	},
	{	//机票退票详情页
		path: 'tkRefundDetail',
		name:'TkRefundDetail',
		component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/tk/TkRefundDetail"),
		meta:{
			title:"机票退票订单详情"
		}
	},
	{	//机票改签详情页
		path: 'tkChangeDetail',
		name:'TkChangeDetail',
		component:  () => import( /* webpackChunkName: "routeMain" */ "@/views/tk/TkChangeDetail"),
		meta:{
			title:"机票改签订单详情"
		}
	},
]



export  {routeTk,routeTkEdit,routeTkDetail}