<template>
  <div class="yj-space" :style="{'width':size+'px','height':size+'px'}"></div>
</template>

<script>
export default {
  name: "Space",
  props: {
    size:{
      type: [Number, String],
      default: 0,
    },
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {
  },
};
</script>

<style scoped lang="scss">
</style>
