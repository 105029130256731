
// 主页面
let langMain = {
	// 导航
	homeTab:{
		home:'Home',
		book:'Book',
		myTravel:'MyTravel',
		myBill:'MyBill',
		myApp:'Download',
	},
	login: {
	  introduction: 'welcome to my system',
	  corpNo_holder:"CorpNo",
	  name_holder: 'Username',
	  password_holder: 'Password',
	  corpNo_tip: 'Please enter the CorpNo',
	  name_tip: 'Please enter the username',
	  password_tip: 'Please enter the password',
	  passwordT1: 'Please enter 6-20 characters, including characters / numbers',
	  enterAgain: 'Enter again',
	  remember: 'Remember Me',
	  forget: 'Forgot Password',
	  info: 'Please call 911',
	  button: 'Login',
	  phoneNumber_holder: 'phone number',
	  verifyCode:'Verification Code',
	  getYzmCode:'Get Verification Code',
	  resend: 'Resend',
	  phone_tip:'Please enter your mobile phone number',
	  verifyCode_tip:'Please enter the verification code',
	  personRegister:'Personal registration',
	  corpRegister:'Enterprise registration',
	  serviceAndPrivacy:'Please agree to the service agreement and privacy terms first',
	  termsOfService: "Please agree to the terms of service first",
	  serviceTelephone: "Service Telephone",
	  enterpriseContact: "Enterprise Contact",
	  servicePersonTimes:"Service person times",
	  saveTravelServiceCB:"Save travel costs for service enterprises",
	},
	register:{
		username: 'Username',
		phoneNumber: "phone number",
		verifyCode: 'Verification Code',	
		submitRegister: 'Submit registration',
		login: 'Login',
		haveAnAccount: 'Have an account to go',
		phoneNumber_tip:'Please enter your mobile phone number',
		verifyCode_tip:'Please enter the verification code',
		username_tip:'Please enter your name',
		invalidPhone:'Invalid mobile number',
		registerSuccess:'login was successful',
		enterpriseFullname:'Please enter the full name of the enterprise',
		enterpriseShortname: 'Please enter enterprise abbreviation',
		enterpriseAddress: 'Please enter the business address',
		industry:'Please select enterprise industry',
		corpScale:'select enterprise size',
		provinceName:'Please select a province name',
		cityName:'Please select a city name',
		linkPhone:'Please enter the contact number',
		linkman:'Please enter contact',
		postCode:'Please enter zip code',
		smsCode:'Please enter the verification code',	
		email:'Please enter email address',
		correctEmail:'Please enter the correct email format',
		passwordLeng:"The password is 6 to 20 digits long",
		passwordType:"The password is a 6-20 half width character composed of letters plus numbers or symbols. It is case sensitive and cannot contain spaces",
		passwordAtypism:"The two passwords are inconsistent",
	}	

	
}


export default langMain