<template>
	<el-card class="cardText">
		<div class="title">需要我审批</div>
		<div class="spmain">
			<div class="spselcet">
				<el-radio v-model="radio" label="1">同意</el-radio>
				<el-radio v-model="radio" label="0">拒绝</el-radio>
			</div>
			<el-input
			  type="textarea"
			  placeholder="请输入内容"
			  maxlength="200"
			  v-model="appRemark">
			</el-input>
			<span v-if="200-appRemark.length==0">
				可输入文字已达到上限
			</span>
		</div>
		<div>
			<el-button @click="myAppDetail">审 批</el-button>
		</div>
	</el-card>
</template>

<script>
	export default{
		name: 'NeedMyApproval',
		data() {
			return {
				radio: '1',
				appRemark :'', // 审批备注
			}
		},
		props:{
			workItemId:{
				type: String,
				default: '',
			},
			orderId:{
				type: String,
				default: '',
			}
		},
		methods:{
			// 审批
			myAppDetail(){
				if(this.radio == 1){
					if(!this.appRemark){
						this.appRemark = '同意'
					}
				}else{
					if(!this.appRemark){
						this.$alert('请输入拒绝原因','',{
							confirmButtonText: '确定',
							type: 'warning',
						})
						return false
					}
				}
				let queryString = {
					appRemark: this.appRemark,
					completeState: this.radio,
					completeWay: 1,
					workItemId: this.workItemId,
					orderId: this.orderId,
				}
				this.$conn.getConn('commu.myAppDetail')(queryString,(res)=>{
					this.$emit('spSuccess')
					this.$message.success('审批成功')
				},(err)=>{
					
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.cardText{
		/deep/ .el-card__body{
			padding: 30px;
		}
		font-family: '微软雅黑';
		.title{
			font-size: 24px;
			font-weight: 600;
			color: #424242;
			line-height: 24px;
			text-align: left;
		}
		.spmain{
			margin-top: 38px;
		}
		.spselcet{
			text-align: left;
			padding-bottom: 10px;
		}
		.el-button{
			width: 100px;
			height: 40px;
			margin-top: 20px;
		}
	}
</style>
