<template>
	<div class="ul-main">
		<el-upload
			class="avatar-uploader"
			ref="upload"
			:action="path"
			accept="image/*"
			:limit="1"
		    name="file"
			:headers="{
				token:token
			}"
			:data="{
				sysCode:'tmc-etsms',
				timeout: -1,
				token:token
			}"
			:show-file-list="false"
			:on-success="handleAvatarSuccess"
			:on-error="handleError"
			:before-upload="beforeAvatarUpload"
			>
				<el-image v-if="imageUrl" class="avatar" :src="imageUrl"></el-image>
			<i v-else class="el-icon-plus avatar-uploader-icon"></i>
		</el-upload>
	</div>
</template>

<script>
	
	export default{
		name:'UpLoad',
		data(){
			return {
				imageUrl: '',
			}
		},
		computed:{
			// 总公司信息
			companyData(){ 
				let info = this.$common.getConfigData();
				return info || {};
			},
			//
			path(){
				let path = this.$conn.getUrl('common.upload');		
				return path;
			},
			token(){
				let token = this.$common.sessionget('access_token');
				return token;
			}
		},
		methods:{
			
			handleAvatarSuccess(res, file) {
				
				let nowurl = res.data || '';				
			    this.imageUrl = URL.createObjectURL(file.raw);
			
				this.$emit('successfile', nowurl)
			},
			handleError(res, file) {
			 
			},
			beforeAvatarUpload(file) {
			 
			  const isLt2M = file.size / 1024 / 1024 < 500;
			  if (!isLt2M) {
			    this.$message.error('上传头像图片大小不能超过 500MB!');
			  }
			  return  isLt2M;
			}      
		}
	}
</script>

<style lang="scss" scoped>
	.ul-main{
		.avatar-uploader {
		    border: 1px dashed #d9d9d9;
		    border-radius: 6px;
		    cursor: pointer;
		    overflow: hidden;
			margin-top: 20px;
			width: 160px;
			height: 160px;
			/deep/ .el-input__inner{
				width: 160px;
				height: 160px;
				border: none;
			}
		  }
		  .avatar-uploader:hover {
		    border-color: #409EFF;
		  }
		  .avatar-uploader-icon {
		    font-size: 28px;
		    color: #8c939d;
		    width: 160px;
		    height: 160px;
		    line-height: 160px;
		    text-align: center;
		  }
		  .avatar {
		    width: 160px;
		    height: 160px;
		    display: block;
		  }
	}
</style>
