<template>
	<div>
		<a href="#/yjTravel/home" >
			<img class="ctriplogo" :src="logoImg" :alt="logoTitle">
		</a>
	</div>
	
</template>

<script>
	// 像素是  406 x 68 px 格式是 png
	import kgjh_logo from '@/assets/image/logo/kgjh_logo.png';
import yjsl_logo from '@/assets/image/logo/yjsl_logo.png';
	export default {
		name:'NavLogo',
		data() {
			return {
				logoImg:'',
				logoTitle:'',
				logoMap:{
					yjsl:{
						logoImg:yjsl_logo,
						logoTitle:'壹嘉商旅',
					},
					kgjh:{
						logoImg:kgjh_logo,
						logoTitle:'空港嘉华',
					},
					xjkgjh:{
						logoImg:require('@/assets/image/logo/jhyx_logo.png'),
						logoTitle:'嘉华易行',
					},
				}
			}
		},
		mounted() {
			
			var configObj = this.$common.getConfigData();
			var pageSettingInfo = this.$common.getPageSettingInfo();
			
			if(configObj.homePageType && this.logoMap[configObj.homePageType]){
				this.logoImg = this.logoMap[configObj.homePageType]['logoImg'] || '';
				this.logoTitle = this.logoMap[configObj.homePageType]['logoTitle'] || '';
			}else if(configObj.homePageType && !this.logoMap[configObj.homePageType]){
				this.logoImg = pageSettingInfo.logoUrl || configObj['logoImg'] ||  '';
				this.logoTitle = configObj['corpName'] || '';
			}else{
				this.logoImg = yjsl_logo || '';
				this.logoTitle =  '壹嘉商旅';
			}
			
		},
	}
</script>

<style scoped>

	.el-header-content .logo img.ctriplogo,.ctriplogo{
		float: left;
		margin: 23px 0;
		width: 210px;
		height: 34px;
	}
</style>