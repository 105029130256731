<template>
	<div class="tc-main">

		<el-popover placement="bottom-start" width="500" :trigger="disabled?'manual':'click'" ref="popCity"
			popper-class="my-pop-train-city-lang" :popper-append-to-body="false" v-model="visible"
			@hide="hidePopverCity"
			@show="showPopverCity">
			<div slot="reference">
				<slot name="content"></slot>
			</div>
			<div v-if="!searchCityBool" class="trc-city">
				<div class="trc-title">
					<div class="title-color" :class="{'trc-city-title':index==currentIndex,'border-left':index>0 && index==currentIndex,'border-right':index<6 && index==currentIndex}" v-for="(item,index) in cityList" :key="index" @click="currentType(index,item)">
						{{item.titleName}}
					</div>
				</div>
				<div class="tr-cont">
					<div v-for="cItem in currentCityList" :key="cItem.name" class="tr-con">
						<div class="tr-con-left" >
							<el-button type="text" style="cursor: initial;">{{cItem.name}}</el-button>
						</div>
						<div  class="tr-con-right">
							<span v-for="vItem in cItem.value" :key="vItem.id+''+vItem.csbh">
								<el-tooltip  effect="dark" :open-delay="500" :content="vItem.cityName" placement="top">
									<div slot="content">
										<span>
											<span>{{vItem.cityName}}</span>
											<span v-show="vItem.airportName">-{{vItem.airportName}}</span>
											<span>({{vItem.threeCode}})</span>
										</span>
									</div>
									<el-button class="trc-city-btn" @click="checkBtn(vItem)">
										{{vItem.cityName}}
									</el-button>
								</el-tooltip>
							</span>
						</div>
					</div>
				</div>
				<!-- <div v-if="currentCityList.length==0" style="padding:20px;">
					<span class="el-icon-loading"></span>城市数据加载中...
				</div> -->
			</div>
			<div v-else class="searchMar">
				<div>可输入中文/全拼/简拼/城市名称</div>
				<div class="search-trc-city" v-if="searchCityListResultCurrent.length > 0">
					<p v-for="(item, index) in searchCityListResultCurrent" :key="index"
						:class="['city-result', index == 0 ? 'red' : '']" @click="checkBtnSearch(item)">
						<span>{{ item.airportName}}</span>
						<span>{{ item.cityName}}</span>
					</p>
					<yj-pagination :small="true" @handleCurrentChange="handleCurrentChange"
						:total="searchCityListResult.length" :currentPage="currentPageCity"></yj-pagination>
				</div>
				<el-empty description="暂无数据" v-else></el-empty>
			</div>
		</el-popover>
	</div>
</template>

<script>
	
	export default {
		name: 'TrCity',
		props: {
			keywords: {
				type: String,
				default: () => {
					return ''
				}
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			// 是否弹出站点选择
			isModalStation:{
				type:Boolean,
				default: false,
			}
		},
		data() {
			return {
				cityList: [
					{  
						title: 'HOT',
						titleName: '热门',
						content: [],	
						have:false,
					},
					{
						title: 'ABCDE',
						titleName: 'ABCDE',
						content: [],
						have:false,
					},
					{
						title: 'FGHJK',
						titleName: 'FGHJK',
						content: [],
						have:false,
					},
					{
						title: 'LMNP',
						titleName: 'LMNP',
						content: [],
						have:false,
					},
					{
						title: 'QRST',
						titleName: 'QRST',
						content: [],
						have:false,
					},
					{
						title: 'WXYZ',
						titleName: 'WXYZ',
						content: [],
						have:false,
					},	
					
				], // 城市列表  
				activeIndex: -1,
				activeIndexSub: -1,
				inputCity: '', // 输入城市
				searchCityBool: false, //搜索城市数据
				visible: false,
				currentPageCity: 1, //默认城市
				searchCityList: [], //搜索城市列表
				searchCityListResult: [], // 查询后的城市数据
				searchCityListResultCurrent: [], // 查询后的城市数据分页
				timeStampCity: 0,
				currentIndex:0, //选择城市的当前索引
				currentCityList: [], // 当前城市信息
			}
		},
		mounted() {
			if (this.disabled) {
				this.visible = false;
			}
			let trSearchCityList = this.$common.localget('trSearchCityList');
			
			if (trSearchCityList && trSearchCityList.length>0) {
				this.searchCityList = trSearchCityList;
			} else {
				this.getSearchCity();
				
			}
			
			let trainZdVersion = this.$conn.getConn('staticData.trainZdVersion');
			trainZdVersion({}, (resp) => {
			  let data = resp.data || {};
			  let trainZdVersion = data.trainZdVersion;
			  // 缓存的版本号
			  let _trainZdVersion = this.$common.localget('_trainZdVersion');
			  if (!!_trainZdVersion && trainZdVersion == _trainZdVersion - 0) {
					let trCityList = this.$common.localget('trCityList');
					if(trCityList && trCityList[0].have == true && trCityList[1].have == false){
						//获取火车票 热门 a-z 第一条数据是否为空
						this.cityList = trCityList;
					}else{
						this.getHotCity();
						this.getTrCity();
					}
			  } else {
			    this.getHotCity();
			    this.getTrCity();
			    common.localset('_trainZdVersion',trainZdVersion);
			  }
			}, err => {
			  mui.alert(err.tips);
			})
			
			
			
			
			
		},
		watch: {
			keywords(val) {
				this.inputCity = val;
				this.inputCityEvent(val);
			}
		},
		methods: {
			
			// 获取城市数据
			getTrCity() {
				let cityInfo = {};
				this.$conn.getConn('staticData.cityStationH5', {jsfile:true})({},(res) => {
					cityInfo = res || {};
					if(JSON.stringify(cityInfo)=="{}"){
						this.$message({
							type:'warning',
							message:'未查到火车票城市信息'
						})
						return 
					}else{
						this.cityList.map((item,index)=>{
							let arr = []
							if(index==0){
									
							}else{
								for(let key in cityInfo){
									if(item.title.indexOf(key) != -1){
										let arrObj = {
											name: key,
											value: cityInfo[key],
										}
										arr.push(arrObj)
									}
								}	
								this.cityList[index].content = arr;
								this.cityList[index].have = false;
								this.cityList = this.$common.deepCopy(this.cityList)
							}
						})	
						this.$common.localset('trCityList', this.cityList);
					}	
				})			
			},
			// 获取热门城市
			getHotCity(){
				this.$conn.getConn('staticData.trainCity',{jsfile:true})({},(res) => {
					let data = res || {};	
					if(JSON.stringify(data)=="{}"){
						this.$message({
							type:'warning',
							message:'未查到热门城市信息'
						})
						return 
					}else{
						let arr = [];
						arr.push({
							name: 'HOT',
							value: data['HOT'],
						})
						this.cityList[0].content = arr;
						this.cityList[0].have = true;
						this.currentCityList = data;
						this.cityList = this.$common.deepCopy(this.cityList);
						this.$common.localset('trCityList', this.cityList);
					}	
					
				})
			},
			// 获取搜索城市数据
			getSearchCity() {
				this.searchCityList = [];
				this.$conn.getConn('staticData.cityStation', {
					jsfile: true
				})().then((res) => {
					this.searchCityList = res || [];
					this.$common.localset('trSearchCityList', this.searchCityList);
				})
			},
			//选择城市title的索引
			currentType(index,item){
				this.currentIndex = index;
				this.currentCityList = item.content;
			},
			// 城市关键字查询
			inputCityEvent(e) {
				let term = e;
				if (!term) {
					this.$emit('checkTrCity','')
					this.searchCityBool = false;
					return;
				}
				this.timeStampCity = 1; // 1s延时
				let chReg = /^[\u4e00-\u9fa5]+$/;
				let enReg = /^[a-zA-Z]+$/
				setTimeout(() => {
					if (this.timeStampCity - 1 === 0) {
						this.$refs.popCity.doShow();
						if (/^[0-9]/.test(term)) {
							this.searchCityListResult = [];
						}
						this.searchCityListResult = [];

						let chResolve = chReg.test(term);// 中文验证
						let enResolve = enReg.test(term);// 字母验证
						let searchCityList = this.searchCityList;
						
						for (var cityindex in searchCityList) {
							if (chResolve) {
								if (searchCityList[cityindex].airportName.indexOf(term) != -1) {
									this.searchCityListResult.push(searchCityList[cityindex]);
								}
							} else if (enResolve) {
								let mather = new RegExp("^" + term, "i");

								if (mather.test(searchCityList[cityindex].fullspell) || mather.test(searchCityList[
										cityindex].pyjsm)) {
									this.searchCityListResult.push(searchCityList[cityindex]);
								}
							} else {
								this.searchCityListResult = []
							}

						}
						this.searchCityBool = true;
						this.searchCityListResultCurrent = [];
						this.searchCityListResult.map((v, i) => {
							if (i < 10) {
								this.searchCityListResultCurrent.push(v);
							}
						});
					}
				}, 500);
					
				
				
			},
			// 监听弹框显示时的方法
			showPopverCity() {
				this.currentCityList = this.cityList[this.currentIndex].content || [];
				
			    
				if(this.isModalStation){
					this.visible = true;
				}else{
					
					this.visible = false
					this.$emit("trChangeRouteRole")
				}
			},
			// 隐藏搜索城市
			hidePopverCity() {
				this.searchCityBool = false;
			},
			// 选中热门城市
			checkBtn(val) {
				this.visible = false;
				this.inputCity = val.cityName;
				val.cityInput = (val.cityName || '') + (val.threeCode ? `(${(val.threeCode || '')})` : "");
				this.$emit("checkTrCity", val);
			},
			// 选中搜索城市
			checkBtnSearch(val) {
				this.inputCity = val.airportName;
				val.cityInput = (val.airportName ? val.airportName : '') + (val.threeCode ? `(${(val.threeCode || '')})` :
					"");
				this.$emit("checkTrCity", val);
				this.$refs.popCity.doClose();

			},
			// 分页数据
			handleCurrentChange(val) {
				this.searchCityListResultCurrent = [];
				this.searchCityListResult.map((v, i) => {
					if (i < val * 10 && i >= (val - 1) * 10) {
						this.searchCityListResultCurrent.push(v);
					}
				});
			},

		},
	}
</script>

<style lang="scss">
	.el-popover.my-pop-train-city-lang {
		padding: 0;

		.searchMar {
			margin: 12px;
		}

		.search-trc-city {
			padding-top: 10px;

			.city-result {
				display: flex;
				justify-content: space-between;
				padding: 10px;

				
			}
			.city-result:hover {
				background-color: #eee;
				cursor: pointer;
			}
			.red {
				color: #f00;
			}
		}

		.trc-city {
			height: 240px;
			overflow: hidden;
			.trc-title{
				display: flex;
				height:40px;
				line-height: 40px;		
				border-bottom: 1px solid #EEEEEE;
				.border-left{
					border-left: 1px solid #EEEEEE;
				}
				.border-right{
					border-right: 1px solid #EEEEEE;
				}
				.trc-city-title {
					color:#00A7FA;
					height: 40px;
					background-color: #FFFFFF;
					
				}
				.title-color{
					width: 83px;
					box-sizing: border-box;
					text-align: center;
				}
				.title-color:hover{
					color: #00A7FA;	
					cursor: pointer;
				}
			}
			.tr-cont{
				overflow-y: auto;
				overflow-x: hidden;
				height: 199px;
				padding-top: 5px;
				.tr-con{
					width:498px;
					display: flex;
					justify-content: flex-start;
					flex-wrap: wrap;
					.tr-con-left{
						width: 10%;
						text-align: center;
						cursor: initial;
					}
					.tr-con-right{
						width: 90%;
					}
					
					.trc-city-btn {
						margin-left: 0;
						border: none;
						display: inline-block;
						width: 85px;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						cursor: pointer;
					}
				}
				
			}
			.tr-cont::-webkit-scrollbar {
				width: 6px;
			}
			
			.tr-cont::-webkit-scrollbar-track {
				background-color: #EEEEEE;
			}
			
			.tr-cont::-webkit-scrollbar-thumb {
				background-color: #398EFE;
			}	
		}
	}
</style>
