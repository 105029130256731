const anchor = {}
//锚点跳转
anchor.goAnchor = (that,selector)=>{
    var anchor = that.$el.querySelector(selector);//获取元素
    if(anchor) {
        setTimeout(()=>{//页面没有渲染完成时是无法滚动的，因此设置延迟
            anchor.scrollIntoView(); //js的内置方法，可滚动视图位置至元素位置
        },500);
    } 
};
//获取url参数
anchor.GetQueryString = function (name){
     var reg = new RegExp("(^|&)"+ name +"=([^&]*)(&|$)");
     var r = window.location.search.substr(1).match(reg);
     if(r!=null)return  unescape(r[2]); return null;
}
 
export default anchor