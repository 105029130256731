import trMap from './trMap.js'
import common from '@/plugins/common.js'
const mutations = {
	// 设置查询参数
	setSearchData(state, obj) {
		for(var key in obj) {
			state.trainQuery.searchData[key] = obj[key];
		}
	},
	// 设置火车票数据
	setTrainData(state, obj) {
		for(var key in obj) {
			state.trainData = obj[key];
		}
	},

	// 出行人列表
	setCxrList(state, obj) {
		state.cxrList = obj;
	},
	// 火车票临时出行人列表
	setLsCxrList(state, obj) {
		state.lsCxrList = obj;
	},
	
	// 乘车人列表
	setPassengerlength(state, obj){
		state.passengerLength = obj;
	},
	
	// 火车票预定页数据  审批 出差项目
 	setTrainEdit(state, obj){
		for(var key in obj) {
			state.trainEdit[key] = obj[key];	
		}
	},
	// 存储预定信息
	setTrainYdInfo(state, obj){
		state.trainYdInfo = obj;	
	},
	//清除火车票缓存信息  退出时 使用
	initTrInfo(state, obj){
		let trMapCopy = common.deepCopy(trMap)
		for(var key in trMapCopy){
			state[key] = trMapCopy[key];
		}
	}
}
export default mutations