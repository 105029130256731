/*
 *   本文件为 个人中心
 *
 */ 

const userCenter = [
			{   
				path: 'userCenter',
				component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/UserCenter"),
				redirect: 'userCenter/order' ,
				children:[
					{
						path: 'travelInfo',
						name:'TravelInfo',
						component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/TravelInfo"),
						meta:{
							title:"差旅信息"
						}
					},
					{
						path: 'order',
						name:'Order',
						component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/Order"),
						meta:{
							title:"订单中心"
						}
					},
					{
						path: 'travelFormAdmin',
						name:'TravelFormAdmin',
						component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/TravelFormAdmin"),
						meta:{
							title:"出差管理"
						}
					},
					{
						path: 'approvalRecord',
						name:'ApprovalRecord',
						component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/ApprovalRecord"),
						meta:{
							title:"审批记录查询"
						}
					},
					{
						path: 'needMyApproval',
						name:'NeedMyApproval',
						component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/NeedMyApproval"),
						meta:{
							title:"需要我审批"
						}					
					},
					{
						path: 'comInfo',
						name:'ComInfo',
						component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/ComInfo"),
						children:[
							{
								path: 'passenger',
								name:'Passenger',
								component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/comInfo/Passenger"),
								meta:{
									title:"常旅客"
								}
							},
							{
								path: 'linkman',
								name:'Linkman',
								component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/comInfo/Linkman"),
								meta:{
									title:"常用联系人"
								}
							},
							{
								path: 'address',
								name:'Address',
								component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/comInfo/Address"),
								meta:{
									title:"常用地址"
								}
							},
							{
								path: 'invoice',
								name:'Invoice',
								component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/comInfo/Invoice"),
								meta:{
									title:"发票"
								}
							},
						]
					},
					
					{
						path: 'personCetner',
						name:'PersonCenter',
						component:  () => import( /* webpackChunkName: "routeMain" */ "./../../views/myTravel/ucComponents/PersonCenter"),
						meta:{
							title:"个人信息"
						}
					},
				]
			},	 

	
]

export default userCenter