/**
 * @brief 节流函数
 * @param fn 事件回调
 * @param wait 推迟执行的等待时间
 * 
 * 有个毛病， 
 * 多次调用同一方法时，两个条件都会触发，第一没有值，if触发一次，第二次有值，触发else条件 ,
 * 再次点击触发时，走else 条件
 */
 export const throttle = (fn, wait) => {
  let inThrottle, lastFn, lastTime;
  return function() {
    const context = this,
      args = arguments;
    if (!inThrottle) {
      fn.apply(context, args);
      lastTime = Date.now();
      inThrottle = true;
    } else {
      clearTimeout(lastFn);
      lastFn = setTimeout(function() {
        if (Date.now() - lastTime >= wait) {
          fn.apply(context, args);
          lastTime = Date.now();
        }
      }, Math.max(wait - (Date.now() - lastTime), 0));
    }
  };
};