import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from 'moment'
import VueAMap from 'vue-amap'
import pluginsMessage from "./plugins/message"
import anchor from "./plugins/anchor"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 注册全局过滤
import * as filters from './plugins/filter/filter.js'
Object.keys(filters).forEach(key=>{
    Vue.filter(key,filters[key])//插入过滤器名和对应方法
})

//bus （有待研究）
import bus from './utils/bus' 
Vue.prototype.$bus = bus;

// 解决高德地图刷新地图丢失
// const amapKeys = Object.keys(localStorage).filter(key => key.match(/^_AMap_/))
// amapKeys.forEach(key => {
//   localStorage.removeItem(key) //页面刷新清除地图相关的本地存储数据
// })
// 解决高德地图刷新地图丢失
setTimeout(clearAMap,0)
function clearAMap(){
	localStorage.removeItem(/^_AMap_/)
	Vue.use(VueAMap)
	setTimeout(()=>{
		VueAMap.initAMapApiLoader({
			key:'5639a29d708f4e8187c04913596e1b29',
			"version": "1.4.15",
			plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor',"AMap.Geolocation"],
		})
	},10)
}

	


// size 用于改变组件的默认尺寸，zIndex 设置弹框的初始 z-index（默认值：2000）
Vue.use(ElementUI,{ size: 'small', zIndex: 3000 });
import './assets/iconfont/iconfont.css'
// 多语言
import i18n from './i18n/i18n.js'

// http请求
import http from './plugins/http/http.js'
Vue.prototype.$conn = http;

// common 方法
import common from './plugins/common.js';
Vue.prototype.$common = common;

Vue.prototype.$moment = moment
moment.locale('zh-cn')


import dateTime from './plugins/dateTime.js'
Vue.prototype.$dateTime = dateTime;
 
// 字典 配置跳转详情 类型 路由 
 import dict from './plugins/dict/dictCommon.js'
 Vue.prototype.$dict = dict;

import initVuex from './plugins/initVuex.js';
 Vue.prototype.$initVuex = initVuex;

// 打开加载层
Vue.prototype.openLoading = function () {
  const loading = this.$loading({           // 声明一个loading对象
    lock: true,                             // 是否锁屏
    text: '加载中...',                     // 加载动画的文字
    spinner: 'iconfont yj-icon-jh_aircraft',             // 引入的loading图标
    background: 'rgba(0, 0, 0, 0.3)',       // 背景颜色
    target: '.sub-main',                    // 需要遮罩的区域
    body: true,
    customClass: 'mask'                     // 遮罩层新增类名
  })
 //  let timer = setTimeout(function () {                  // 设定定时器，超时5S后自动关闭遮罩层，避免请求失败时，遮罩层一直存在的问题
 //    loading.close();                        // 关闭遮罩层
	// clearTimeout(timer);
 //  }, 20000)
  return loading;
}

  
// 顶部加载条
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
NProgress.configure({
	parent:"body",
    easing: 'ease',  // 动画方式    
    speed: 500,  // 递增进度条的速度    
    showSpinner: false, // 是否显示加载ico
    trickleSpeed: 200, // 自动递增间隔    
    minimum: 0.3 // 初始化时的最小百分比
})

//当路由进入前
 router.beforeEach((to, from , next) => {
     // 每次切换页面时，调用进度条
    NProgress.start();
    // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
     next();
 });
//当路由进入后：关闭进度条
router.afterEach(() => {  
    // 在即将进入新的页面组件前，关闭掉进度条
    NProgress.done()
})
//自定义组件或插件命名
const camelCase = function(name) {
	name = name.substr(0, 1).toLowerCase() + name.substr(1, name.length);
	var reg = /[A-Z]/;
	name = name.split('').map(val => {
		return reg.test(val) ?
			('-' + val.toLowerCase()) :
			val;
	})
	name = 'yj-' + name.join('');
	return name;
}
/*自定义全局组件，components/yjComponents目录下*/
	//如components/yjComponents目录下Header.vue,在页面中使用方式为  <yj-header></yj-header>
var registComponent = function(){
	const requireComponent = require.context(
		'./components/yjComponents',
		false,
		/[A-Z]\w+\.(vue|js)$/
	)
	requireComponent.keys().forEach(fileName => {
		const componentConfig = requireComponent(fileName)
		const componentName = camelCase(
			fileName
			.split('/')
			.pop()
			.replace(/\.\w+$/, '')
		)
		Vue.component(
			componentName,
			componentConfig.default || componentConfig
		)
	})
}
registComponent();


Vue.prototype.$notify = pluginsMessage.notify;
Vue.prototype.$message = pluginsMessage.message;
Vue.prototype.$messageBox = pluginsMessage.messageBox;

Vue.prototype.$anchor = anchor;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
