/*
 *   本文件为 模板打印路由
 *  oaTmc/home
 */ 

const routeOaTmc = [
	{
		path: '/yjTravelTmc',
		component:  () => import(/* webpackChunkName: "oaTmc" */ "@/views/oaTmc/TmcHome"),
		meta:{
			title:"TMC首页"
		}
		
	},
	{
		path: '/tmcCtripHome',
		component:  () => import(/* webpackChunkName: "oaTmc" */ "@/views/oaTmc/TmcCtripHome"),
		meta:{
			title:"TMC首页"
		}
		
	},
	{
		path: '/tmcLogin',
		component:  () => import(/* webpackChunkName: "oaTmc" */ "@/views/oaTmc/TmcLogin"),
		meta:{
			title:"TMC首页"
		}
		
	},
	
]

export default routeOaTmc