<template>
	<div class="train-station" v-if="visible">
		<div class="cityModal">
			<div class="cityHeader">
				<p>请选择城市站点！</p>
				<p>
					<span class="el-icon-close fontIcon" @click="cancel"></span>
				</p>
			</div>
			<div class="cityContent">
				<div class="header" v-if="!ccsqdId">
					请选择出差申请单城市站点
				</div>
				<div class="content">
					<el-collapse>
						<el-collapse-item v-for="(item, keys) in cityInfo" :key="keys" :title="item.cityName">
							<div v-for="cItem in item.cityList" :key="cItem.id" class="cityText" @click="selectStation(cItem,item.cityId)">
								<div :class="{'stationLeft':(cItem.zddm==depSzm || cItem.zddm==arrSzm)}">
									<span>{{cItem.zdmc}}</span>
									<span v-show="cItem.zddm==depSzm" class="colordep">出发</span>
									<span v-show="cItem.zddm==arrSzm" class="colorarr">到达</span>
								</div>	
								<div :class="{'stationRight':(cItem.zddm==depSzm || cItem.zddm==arrSzm)}">
									<span>{{cItem.zddm}}</span>
									<span class="cityIcon">
										<span class="el-icon-success successIcon" v-show="cItem.zddm==depSzm || cItem.zddm==arrSzm"></span>
									</span>
								</div>
							</div>
						</el-collapse-item>
					</el-collapse>
				</div>
			</div>
			<div class="cityFooter">
				<el-button  @click="cancel">取 消</el-button>
				<el-button type="primary" @click="determine">确 定</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState, mapMutations} from 'vuex';
	export default{
		name: 'TrainCityStation',
		data() {
			return {
				cityInfo: {},// 站点信息
				
				depCityId:'', //出发站id
				depSzm:'', // 出发站三字码
				arrSzm:'', // 到达站三字码
				arrCityId:'',// 到达站id
					
				chooseStation: {},			
			}
		},
		props:{
			visible:{
				type: Boolean,
				default: false,
			},
			cityType:{
				type: Number,
				default: 1,
			}
		},
		computed:{
			...mapState({
				searchData: state => state.train.trainQuery.searchData,
			
			}),
			// 出差申请单行程出发城市Id列表
			depCityIdList(){
				return this.searchData.depCityIdList || [];
			}, 
			// 出差申请单行程到达城市Id列表
			arrCityIdList(){
				return this.searchData.arrCityIdList || [];
			}, 
			 // 10991    查询出差申请单可更改行程
			changeRouteRole(){
				return this.searchData.changeRouteRole;
			},
			ccsqdId(){
				return this.searchData.ccsqdId || '';
			},
			depStationInfo(){
				return this.searchData.depStationInfo || {};
			},
			arrStationInfo(){
				return this.searchData.arrStationInfo || {};
			},
		},
		watch:{
			visible:{
				handler(bool){
					if(bool){
						this.getCityInfo()
					}
				},
				immediate: true,
			},
			ccsqdId:{
				handler(newId,oldId){
					if(oldId){
						if(newId!=oldId){
							if(this.changeRouteRole!=3){
								this.cityInfo = {};
							}else{
								// this.removeStation();
								if(this.cityType==1){
									
									this.setSearchData({
										depStationInfo: {},
									})
								}else{
									this.setSearchData({
										arrStationInfo: {},
									})
								}
							}
							
						}
					}
				},
				immediate: true,
				deep: true
			}
		},
		methods:{
			...mapMutations({
				setSearchData: 'train/setSearchData',
			}),
			
			// 取消城市站点
			cancel(){
				this.$emit('cancel',false)
			},
			// 确定城市站点
			determine(){
				if(!this.ccsqdId || JSON.stringify(this.chooseStation)=='{}'){
					this.cancel()
				}else{
					
					this.$emit('chooseStation',this.chooseStation)
				}
				
			},
			// 获取城市信息  cityInfo   可能存在同一城市，不同站点的情况
			getCityInfo(){
				if(!this.ccsqdId){
					return 
				}
				// 已选择出发城市： 城市id 和 站点三字码  cityId、szm  
				this.depCityId = this.searchData.depCityId;
				this.depSzm = this.searchData.depCitySzm;
				// 已选择到达城市： 城市id 和 站点三字码  cityId、szm  
				this.arrSzm = this.searchData.arrCitySzm;
				this.arrCityId = this.searchData.arrCityId;
				// 0 不可更换城市   1 不做限制  2.出发到达可选 3.出发、到达限制可选    
				// 同一城市，均可修改站点信息
				this.cityInfo = {};
				if(this.changeRouteRole ==0){
					if(this.cityType==1){
						this.getCityStation(this.depCityIdList[0])
					}else{
						this.getCityStation(this.arrCityIdList[0])
					}
					
					
				}else if(this.changeRouteRole==2 ){
					
					// 此处为行程中城市任意组合
					// 行程Idl列表
					var allRouteIdList = [];
					allRouteIdList= this.depCityIdList.concat(this.arrCityIdList);
					let currentIdList = [];
					
					
					// 这块站点调用需要优化，不可循环调取，需要手动点击调取  debugger
					for(let i=0;i<allRouteIdList.length;i++){
						if(currentIdList.indexOf(allRouteIdList[i].cityId)==-1){
							currentIdList.push(allRouteIdList[i].cityId)
							this.getCityStation(allRouteIdList[i])
						}
					}	
				}else if(this.changeRouteRole==3){
					let currentIdList = [];
					// 出发到达城市限制可选 出发是出发城市，到达是到达城市
					if(this.cityType==1){
						
						let depCityIdList = this.depCityIdList;
						for(let i=0;i<depCityIdList.length;i++){
							if(currentIdList.indexOf(depCityIdList[i].cityId)==-1){
								this.getCityStation(depCityIdList[i],3)
							}
						}
					}else if(this.cityType==2){
						
						let arrCityIdList = this.arrCityIdList;
						for(let i=0;i<arrCityIdList.length;i++){
							if(currentIdList.indexOf(arrCityIdList[i].cityId)==-1){
								this.getCityStation(arrCityIdList[i],3)
							}
						}
					}
				}
			},
			// 获取站点信息
			getCityStation(obj,type){
				let query = {
					cityId: obj.cityId
				}
				this.$conn.getConn('trBook.trainCityStation2')(query,(res)=>{
					let cityListData = res.data || [];
					cityListData.map(cityItem=>{
						let newCityItem = {
							...cityItem,
							id:cityItem.cityId,
							checked:false,
							cityList:cityItem.stationList || [],
						}
						if(type==3){
							if(this.cityType==1){
								this.depStationInfo[newCityItem.cityId] = newCityItem;
							}else{
								this.arrStationInfo[newCityItem.cityId] = newCityItem;
							}
							
						}else{
							this.cityInfo[newCityItem.cityId] = newCityItem;	
						}
					})
					
					if(type==3){
						if(this.cityType==1){
							this.setSearchData({
								depStationInfo: this.depStationInfo,
							})
							this.cityInfo =this.$common.deepCopy(this.depStationInfo)
							// this.searchCityList = data;
						}else{					
							// this.searchCityList = data;
							this.setSearchData({
								arrStationInfo: this.arrStationInfo,
							})
							this.cityInfo =this.$common.deepCopy(this.arrStationInfo)
							
						}
					}else{
						this.cityInfo =this.$common.deepCopy(this.cityInfo)
					}
					
					// let info = {
					// 		cityName: data[0].cityName,
					// 		cityId: obj.cityId,
					// 		depSzm: obj.depSzm,
					// 		arrSzm: obj.arrSzm,
					// 		checked: false,
					// 		cityList:[],
					// 	};
					
				
				},(err)=>{
					this.$message.error(err.tips)
				})
				
			},
			// 选择城市站点
			selectStation(item,cityId){
				let queryObj = {};
				if(this.cityType==1){
					if(item.zddm == this.arrSzm){
						this.$message.warning('出发到达城市站点不可相同')
						return
					}
					this.depCityId = cityId;
					this.depSzm =item.zddm; // 出发站三字码
					queryObj = {
						depCityId: cityId,
						depCity: item.zdmc, //  出发城市站点
						depCitySzm: item.zddm, //  出发城市三字码
						depInputName: item.zdmc + `(${item.zddm})`, //	
					};
					
				}else{
					if(item.zddm == this.depSzm){
						this.$message.warning('出发到达城市站点不可相同')
						return
					}
					this.arrCityId = cityId;
					this.arrSzm =item.zddm;
					queryObj = {
						arrCityId: cityId,
						arrCity: item.zdmc,//  到达城市
						arrCitySzm: item.zddm, //  到达城市三字码
						arrInputName: item.zdmc + `(${item.zddm})`,
					};					
					
				}	
				this.chooseStation = queryObj;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.train-station{
		font-family: '微软雅黑';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.2);
		z-index: 15;
		.cityModal{
			position: absolute;
			top: 50%;
			left: 50%;
			width: 600px;
			height: 400px;
			background-color: #FFFFFF;
			transform: translate(-50%,-50%);
			.cityHeader{
				display: flex;
				justify-content: space-between;
				padding: 15px 20px ;
				// background-color: rgba(100,100,100,0.2);
				background-color: rgba(50,140,255,0.7);
				font-size: 16px;
				font-weight: bold;
				font-family: '微软雅黑';
				color: #FFFFFF;
				.fontIcon{
					font-size: 20px;
					cursor: pointer;
				}
				.fontIcon:hover{
					color: #ffffff;
				}
			}
			.cityContent{
				height: 290px;
				padding: 20px;
				overflow: hidden;
				.header{
					text-align: left;
					line-height: 20px;
					margin-bottom: 10px;
				}
				.content{
					height: 240px;
					overflow-x: hidden;
					overflow-y: auto;
					/deep/ .el-collapse-item__header{
						font-weight: bold;
						font-size: 14px;
					}
					.cityText{
						display: flex;
						justify-content: space-between;
						text-align: left;
						padding:5px 20px;
						cursor: pointer;
						.stationLeft, .stationRight{
							color: #398EFE;
						}
						.colordep{
							font-size: 12px;
							padding: 0 10px;
							font-weight: 400;
							color: #4AB990;
							background: #E8FAF5;
							display: inline-block;
							margin-left: 10px;
						}
						.colorarr{
							font-size: 12px;
							padding: 0 10px;
							font-weight: 400;
							color: #00aaff;
							background: rgba(0,170,255,0.2);
							display: inline-block;
							margin-left: 10px;
						}
						.cityIcon{
							display: inline-block;
							width: 20px;
							.successIcon{
								display: inline-block;
								font-size: 18px;
								padding-left: 10px;
								color: #398EFE;
							}
						}
					}
					.cityText:hover{
						background-color: #398EFE;
						color: #FFFFFF;
						transition: all 0.2s ease;
					}
					.cityText:hover .colorarr{
						color: #FFFFFF;
						background: none;
					}
					.cityText:hover .colordep{
						color: #FFFFFF;
						background: none;
					}
					.cityText:hover .successIcon{
						color: #FFFFFF;
					}
					.cityText:hover .stationLeft{
						color: #FFFFFF;
					}
					.cityText:hover .stationRight{
						color: #FFFFFF;
					}
					
				}
				.content::-webkit-scrollbar {
					width: 6px;
				}
				
				.content::-webkit-scrollbar-track {
					background-color: #EEF1F6;
				}
				
				.content::-webkit-scrollbar-thumb {
					background-color: #398EFE;
				}	
			}
			.cityFooter{
				padding: 0 20px;
				text-align: right;
				.el-button{
					width: 100px;
					height: 36px;
					font-size: 14px;
				}
			}
		}
	}
</style>
